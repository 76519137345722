import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { LEVITON_SERVER_URL } from "../config";
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setAuthenticated, setRefreshToken } from "../store/AuthActions";
import { useAuthentication } from "../Authentication";
import { setUserID, setUserData } from "../store/AuthActions";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import Spinner from "../components/Spinner";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuthentication();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const authenticated = useSelector(selectAuthenticated)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("This is an error")

    const fetchUserData = async (accessToken) => {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${LEVITON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data.id
                dispatch(setUserID(userid));
                const dataWithoutPassword = { ...data, password: undefined }; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);

        }
    };


    const handleLogin = async (e) => {
        e.preventDefault();
        console.log(`${LEVITON_SERVER_URL}/api/token/`);

        // set error message to null
        setErrorMessage("");
        setShowError(false);

        // check username and password
        if (username === "" || password === "") {
            setErrorMessage("Enter valid username and password");
            setShowError(true);
            return;
        }

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        axios.post(`${LEVITON_SERVER_URL}/api/token/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            console.log("status:", response)
            const { access, refresh } = response.data;
            console.log('Access token received:', access);
            localStorage.clear();
            sessionStorage.clear();
            dispatch(setAccessToken(access));
            dispatch(setRefreshToken(refresh));
            isAuthenticated().then(r => {
            });
            fetchUserData().then(r => {
            })
            navigate("/");
        }).catch((error) => {
            setErrorMessage(error.message)
            setShowError(true)
        })
    };

    const accessToken = useSelector(selectAccessToken)

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (userAuthenticated) {
                navigate("/");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);
    return (
        <div>
            <Navbar page="login" />
            <div className="flex w-full h-full items-center justify-center mt-[5rem]">
            {!authenticated ?
                (
                    <div className=" flex m-4 w-full items-center justify-center">
                        <div
                            className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                            <form className="space-y-6" action="#">
                                <h5 className="text-xl font-medium text-gray-900 dark:text-white">Log in to our
                                    platform</h5>
                                <div>
                                    <label htmlFor="text"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                    <input type="text"
                                        value={username}
                                        name="username"
                                        id="username"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="Username"
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                        }}
                                        required />
                                </div>
                                <div>
                                    <label htmlFor="password"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input
                                        type="password"
                                        value={password}
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        required
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" type="checkbox" value=""
                                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                                required />
                                        </div>
                                        <label htmlFor="remember"
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember
                                            me</label>
                                    </div>
                                    <a href="#"
                                        className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost
                                        Password?</a>
                                </div>
                                <button onClick={handleLogin}
                                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login
                                </button>
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Not registered? <a href="#"
                                        className="text-blue-700 hover:underline dark:text-blue-500"
                                        onClick={() => {
                                            navigate("/signup")
                                        }}>Create account</a>
                                </div>
                            </form>

                            {showError ? (
                                <p className="mt-4 text-sm text-red-600 dark:text-red-400">{errorMessage}</p>
                            ) :
                                (<div>
                                </div>)}
                        </div>

                    </div>)
                :
                (<div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>)
            }
            </div>
        </div>
    )
}
import { useState, useEffect } from 'react';

function useSessionStorage(key, initialValue) {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === key) {
                setValue(event.newValue ? JSON.parse(event.newValue) : null);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [key]);

    const setStoredValue = (newValue) => {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));

        window.dispatchEvent(new StorageEvent('storage', { key: key, newValue: JSON.stringify(newValue) }));
    };

    return [value, setStoredValue];
}

export default useSessionStorage;

const initialState = {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    userid: '',
    authenticated : false,
    user_data : null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ACCESS_TOKEN':
            console.log('Setting access token in reducer:', action.payload); 
            return { ...state, accessToken: action.payload };
        case 'SET_REFRESH_TOKEN':
            console.log('Setting refresh token in reducer:', action.payload); 
            return { ...state, refreshToken: action.payload };
        case 'SET_AUTHENTICATED':
            return {...state, authenticated: action.payload};
        case 'SET_USER_ID':
            console.log('Setting User Id in reducer:', action.payload); 
            return {...state, userid : action.payload}
        case 'SET_USER_DATA':
            console.log('setting user data in reducer:', action.payload);
            return {...state, user_data : action.payload}

        case 'CLEAR_DATA':
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default authReducer;
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from 'axios';
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LEVITON_SERVER_URL } from "../config";
import { selectAuthenticated } from "../selectors";
import { useAuthentication } from "../Authentication";
import Spinner from "../components/Spinner";
import { setAuthenticated } from "../store/AuthActions";

export default function Signup() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [logoPreview, setLogoPreview] = useState(null);
    const [errors, setErrors] = useState({ username: '', mobile: '', email: '', password: '' });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ProjectLogoRef = useRef();
    const { isAuthenticated } = useAuthentication();
    const authenticated = useSelector(selectAuthenticated)

    const handleSignUp = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('mobile_number', mobileNo);
            formData.append('password', password);
            formData.append('profile', ProjectLogoRef.current.files[0])

            const response = await axios.post(`${LEVITON_SERVER_URL}/api/v1/user/create/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate("/login");
            console.log('User registered successfully:', response.data);
        } catch (error) {
            console.error('Error while registering user:', error);
        }
    };

    const handleProjectLogoFileUpload = () => {
        ProjectLogoRef.current.click();
    };

    const handleProjectLogoFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLogoDelete = () => {
        setLogoPreview(null);
        ProjectLogoRef.current.value = null;
    };


    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (userAuthenticated) {
                navigate("/");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <Navbar page="signup" />
            <div className="flex w-full h-full items-center justify-center overflow-y-auto mt-[4rem] ">
            {!authenticated ?
                (
                    <div className=" flex m-4 w-full h-full items-center justify-center my-[2rem] ">
                        <div
                            className="w-full max-w-lg p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto">
                            <form className="space-y-6" action="#">
                                <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign Up</h5>
                                <div className="flex items-center">
                                    {
                                        logoPreview ? (
                                            <div className="bg-[#00C1ED] p-0.5 w-20 h-20 rounded-full mr-6" style={{
                                                backgroundImage: `url(${logoPreview})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}></div>
                                        ) : (
                                            <div
                                                className=" bg-white p-7 rounded-full mr-6 border border-[#7279CC] border-dashed">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.5 7.5C8.19036 7.5 8.75 6.94036 8.75 6.25C8.75 5.55964 8.19036 5 7.5 5C6.80964 5 6.25 5.55964 6.25 6.25C6.25 6.94036 6.80964 7.5 7.5 7.5Z"
                                                        fill="#3B3F6A" />
                                                    <path d="M20 15H17.5V12.5H15V15H12.5V17.5H15V20H17.5V17.5H20V15Z"
                                                        fill="#3B3F6A" />
                                                    <path
                                                        d="M10 17.5H2.5V2.5H17.5V10H20V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366117C19.3995 0.131696 19.0815 0 18.75 0L1.25 0C0.918479 0 0.600537 0.131696 0.366117 0.366117C0.131696 0.600537 0 0.918479 0 1.25L0 18.75C0 19.0815 0.131696 19.3995 0.366117 19.6339C0.600537 19.8683 0.918479 20 1.25 20H10V17.5Z"
                                                        fill="#3B3F6A" />
                                                    <path
                                                        d="M14.535 11.5712L12.5 7.5L8.75 12.5L6.25 10L3.75 15H11.4262C11.6285 14.2197 12.017 13.5001 12.5585 12.9029C13.0999 12.3057 13.7782 11.8487 14.535 11.5712Z"
                                                        fill="#3B3F6A" />
                                                </svg>
                                            </div>
                                        )
                                    }
                                    <div className=" flex flex-col">
                                        <div className=" flex items-center mb-4 cursor-pointer">
                                            <p className=" text-bright-blue mr-4"
                                                onClick={handleProjectLogoFileUpload}>
                                                {
                                                    logoPreview ? "Change Logo" : "Choose File"
                                                }</p>
                                            {
                                                logoPreview ? (
                                                    <div onClick={handleLogoDelete}>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
                                                                fill="#7279CC" />
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            } </div>
                                        <input type="file"
                                            ref={ProjectLogoRef}
                                            id="file_input"
                                            accept="image/*"
                                            onChange={handleProjectLogoFileChange}
                                            style={
                                                { display: 'none' }
                                            } />
                                        <p className=" font-mulish text-sm">.JPG, PNG, SVG
                                            up to 8MB, Recommended size is 256x256px.</p>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="text"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                                        Name</label>
                                    <input type="text"
                                        value={first_name}
                                        name="firstname"
                                        id="firstname"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="First Name"
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                        }}
                                        required />
                                </div>
                                <div>
                                    <label htmlFor="text"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                                        Name</label>
                                    <input type="text"
                                        value={last_name}
                                        name="lastname"
                                        id="lastname"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="Last Name"
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                        }}
                                        required />
                                </div>
                                <div>
                                    <label htmlFor="text"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                    <input type="text"
                                        value={username}
                                        name="username"
                                        id="username"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="Username"
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                        }}
                                        required />
                                </div>

                                <div>
                                    <label htmlFor="email"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input
                                        type="email"
                                        value={email}
                                        name="email"
                                        id="email"
                                        placeholder="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        required
                                        onBlur={() => {
                                            const newErrors = { ...errors, email: '' };
                                            if (!email) {
                                                newErrors.email = 'Email is required.';
                                            } else if (!/\S+@\S+\.\S+/.test(email)) {
                                                newErrors.email = 'Email is not correct.';
                                            }
                                            setErrors(newErrors);
                                        }}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    {errors.email &&
                                        <p className="pointer-events-none animate-wiggle text-sm font-normal text-red-500 mt-2">{errors.email}</p>}
                                </div>
                                <div>
                                    <label htmlFor="text"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile </label>
                                    <input
                                        type="text"
                                        value={mobileNo}
                                        name="mobile"
                                        id="mobile"
                                        placeholder="mobile"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        required
                                        onChange={(e) => {
                                            setMobileNo(e.target.value)
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input
                                        type="password"
                                        value={password}
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        required
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                    />
                                </div>

                                {/* <div className="flex items-start">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                </div>
                                <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                            </div>
                            <a href="#" className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
                        </div> */}
                                <button type="submit" onClick={handleSignUp}
                                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign
                                    Up
                                </button>
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Already registered? <a href="#"
                                        className="text-blue-700 hover:underline dark:text-blue-500"
                                        onClick={() => {
                                            navigate("/login")
                                        }}>Login</a>
                                </div>
                            </form>
                        </div>

                    </div>
                )
                :
                (<div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>)
            }
            </div>
        </div>
    )
}
import PersonImage1 from "../assets/personimage1.jpg"
import PersonImage2 from "../assets/personimage2.jpg"
import PersonImage3 from "../assets/personimage3.jpg"
import PersonImage4 from "../assets/personimage4.jpg"
import PersonImage5 from "../assets/personimage5.jpg"
import ClothImage1 from "../assets/clothimage1.jpg"
import ClothImage2 from "../assets/clothimage2.jpg"
import ClothImage3 from "../assets/clothimage3.jpg"
import ClothImage4 from "../assets/clothimage4.jpg"
import ClothImage5 from "../assets/clothimage5.jpg"

import { useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { LEVITON_SERVER_URL } from "../config";
import Navbar from '../components/Navbar';
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../Authentication";
import Spinner from "../components/Spinner";
import { selectAccessToken, selectAuthenticated } from "../selectors"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../store/AuthActions"
import useSessionStorage from "../store/useSessionStorage"


const PROCESS_URL = `${LEVITON_SERVER_URL}/api/v1/tryon/`
const PROCESS_RESULT_URL = `${LEVITON_SERVER_URL}/api/v1/tryon/results?process_id=`

function VirtualTryOn() {
    const dispatch = useDispatch()
    const [selectedPersonImage, setSelectedPersonImage] = useState(PersonImage1)
    const [selectedClothImage, setSelectedClothImage] = useState(ClothImage1)
    const [showModal, setShowModal] = useState(false)
    const [resultUrl, setResultUrl] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [selectedPersonFile, setSelectedPersonFile] = useState("")
    const [selectedClothFile, setSelectedClothFile] = useState("")
    const [selectedUrl, setSelectedUrl] = useState("")
    const [modalType, setModalType] = useState("")
    const formRef = useRef(null);
    const [showFileUploadError, setShowFileUploadError] = useState(false);
    const [fileUploadError, setFileUploadError] = useState("Error");
    const { isAuthenticated } = useAuthentication();
    const accessToken = useSelector(selectAccessToken)
    const authenticated = useSelector(selectAuthenticated);



    const navigate = useNavigate()
    function changePersonImage(image) {
        setSelectedPersonImage(image)
        setSelectedPersonFile("")
    }

    function changeClothImage(image) {
        setSelectedClothImage(image)
        setSelectedClothFile("")
    }


    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/login");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);


    // useEffect(() => {
    //     const checkAuthentication = async () => {
    //         const userAuthenticated = await isAuthenticated();
    //         dispatch(setAuthenticated(userAuthenticated));
    //         if (!userAuthenticated) {
    //             navigate("/login");
    //         }
    //     };

    //     if (!authenticated) {
    //         checkAuthentication();
    //     }
    // }, [ authenticated, ]);


    function startTryOn() {
        console.log("Selected person image:", selectedPersonImage, selectedClothImage,
            selectedPersonFile, selectedClothFile)
        setIsProcessing(true)
        let data = new FormData();

        fetch(selectedPersonImage).then(res => res.blob())
            .then(blob => {
                // use blob...
                console.log("Selected person blob:", blob)
                let filename
                if (selectedPersonFile !== "") {
                    filename = selectedPersonFile.name;
                } else {
                    filename = selectedPersonImage.replace(/^.*[\\/]/, '')
                }

                console.log(filename)
                data.append('person_image', blob, filename);

                fetch(selectedClothImage).then(res => res.blob())
                    .then(blob => {
                        // use blob...
                        console.log(blob)
                        let filename1
                        if (selectedClothFile !== "") {
                            filename1 = selectedClothFile.name;
                        } else {
                            filename1 = selectedClothImage.replace(/^.*[\\/]/, '')
                        }

                        data.append('cloth_image', blob, filename1);
                        console.log(filename1)
                        console.log("Data:", data)
                        axios.post(PROCESS_URL, data, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then((response) => {
                                //handle success
                                console.log(response)
                                if (response.data.status === "success") {
                                    let process_id = response.data.process_id;
                                    let result_url = PROCESS_RESULT_URL + process_id;
                                    console.log("Result url:", resultUrl)
                                    setResultUrl(result_url)
                                    setIsProcessing(false)
                                } else {
                                    console.log(response.data.message)
                                    setIsProcessing(false)
                                }
                            }).catch((error) => {
                                //handle error
                                console.log(error)
                                setIsProcessing(false)
                            });
                    });
            });
    }

    function uploadImage() {
        console.log(selectedClothFile, selectedPersonFile, selectedUrl)
        if (modalType === "person_image") {
            if (selectedPersonFile !== "") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setSelectedPersonImage(e.target.result);
                };
                reader.readAsDataURL(selectedPersonFile);
            } else if (selectedUrl !== "") {
                setSelectedPersonImage(selectedUrl);
                setSelectedPersonFile("")
                setSelectedClothFile("")
            }
        } else if (modalType === "cloth_image") {
            if (selectedClothFile !== "") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setSelectedClothImage(e.target.result);
                };
                reader.readAsDataURL(selectedClothFile);
            } else if (selectedUrl !== "") {
                setSelectedClothImage(selectedUrl);
                setSelectedPersonFile("")
                setSelectedClothFile("")
            }
        }

        setShowModal(false);
        setSelectedUrl("");
        setModalType("");
    }

    function handleFileChange(event) {
        setShowFileUploadError(false);
        setFileUploadError("")
        const file = event.target.files[0];
        if (file) {
            if (['png', 'jpg', 'jpeg'].includes(getExtensionUsingRegex(file.name))) {
                if (modalType === "person_image") {
                    setSelectedPersonFile(file)
                } else if (modalType === "cloth_image") {
                    setSelectedClothFile(file)
                }
            } else {
                if (formRef.current) {
                    formRef.current.reset();
                }
                setShowFileUploadError(true);
                setFileUploadError("Please upload images with .png, .jpg, or .jpeg extensions only")
            }
        } else {
            if (formRef.current) {
                formRef.current.reset();
            }
            setShowFileUploadError(true);
            setFileUploadError("Please upload images with .png, .jpg, or .jpeg extensions only")
        }
    }

    const getExtensionUsingRegex = (fileName) => {
        const regex = /(?:\.([^.]+))?$/;
        const match = regex.exec(fileName);
        return match ? match[1] : ''; // Return captured group or empty string if no extension found
    };

    return (
        <div>
            <Navbar page="virtual_tryon" />
            <div className="flex h-full w-full mt-[4rem] items-center justify-center">
            {authenticated ?
                (<div>
                    <div className="max-w-7xl mx-auto">
                        <h1 className="px-4 mt-4 text-base font-extrabold leading-none tracking-tight text-gray-900
                        md:text-xl lg:text-2xl dark:text-white">Virtual Try-On</h1>

                        <div className="lg:grid lg:grid-cols-3 lg:gap-4 p-4 mt-2 rounded-lg">
                            <div className="shadow-lg p-4 rounded-lg mb-4 bg-white">
                                <div className="flex justify-between">
                                    <h3>Select/Upload Model Image</h3>
                                    <button className="text-sm text-blue-500 bg-blue-50 px-2 py-1 rounded"
                                        onClick={() => {
                                            setShowModal(true);
                                            setModalType("person_image");
                                        }}>+Upload Image
                                    </button>
                                </div>
                                <div className="h-[28rem] w-full flex justify-center mt-4 rounded-lg">
                                    <img src={selectedPersonImage} className="h-full w-full object-contain rounded-lg" alt="" />
                                </div>

                                <div className="flex justify-start overflow-scroll mt-4 gap-2">
                                    <img src={PersonImage1} className="h-32 rounded-lg cursor-pointer"
                                        onClick={() => changePersonImage(PersonImage1)} alt="" />
                                    <img src={PersonImage2} className="h-32 rounded-lg cursor-pointer"
                                        onClick={() => changePersonImage(PersonImage2)} alt="" />
                                    <img src={PersonImage3} className="h-32 rounded-lg cursor-pointer"
                                        onClick={() => changePersonImage(PersonImage3)} alt="" />
                                    <img src={PersonImage4} className="h-32 rounded-lg cursor-pointer"
                                        onClick={() => changePersonImage(PersonImage4)} alt="" />
                                    <img src={PersonImage5} className="h-32 rounded-lg cursor-pointer"
                                        onClick={() => changePersonImage(PersonImage5)} alt="" />
                                </div>
                            </div>
                            <div className="shadow-lg p-4 rounded-lg mb-4 bg-white">
                                <div className="flex justify-between">
                                    <h3>Select/Upload Product Image</h3>
                                    <button className="text-sm text-blue-500 bg-blue-50 px-2 py-1 rounded"
                                        onClick={() => {
                                            setShowModal(true);
                                            setModalType("cloth_image");
                                        }}>+Upload Image
                                    </button>
                                </div>

                                <div className="h-[28rem] w-full flex justify-center mt-4 rounded-lg">
                                    <img src={selectedClothImage} className="h-full object-contain rounded-lg" alt="" />
                                </div>

                                <div className="flex justify-start overflow-scroll mt-4 gap-2">
                                    <img src={ClothImage1} className="h-32 rounded-lg cursor-pointer" alt=""
                                        onClick={() => changeClothImage(ClothImage1)} />
                                    <img src={ClothImage2} className="h-32 rounded-lg cursor-pointer" alt=""
                                        onClick={() => changeClothImage(ClothImage2)} />
                                    <img src={ClothImage3} className="h-32 rounded-lg cursor-pointer" alt=""
                                        onClick={() => changeClothImage(ClothImage3)} />
                                    <img src={ClothImage4} className="h-32 rounded-lg cursor-pointer" alt=""
                                        onClick={() => changeClothImage(ClothImage4)} />
                                    <img src={ClothImage5} className="h-32 rounded-lg cursor-pointer" alt=""
                                        onClick={() => changeClothImage(ClothImage5)} />
                                </div>
                            </div>
                            <div className="shadow-lg p-4 rounded-lg mb-4 bg-white">
                                <h3>Try On Result</h3>

                                <div className="h-[28rem] w-full flex justify-center mt-4 rounded-lg bg-slate-50">
                                    {isProcessing ? (
                                        <div className="animate-pulse h-full bg-slate-200">

                                        </div>
                                    ) : (<img src={resultUrl} className="h-full object-contain rounded-lg" alt="" />)}
                                </div>

                                <div className="flex justify-center mt-4">
                                    <button
                                        className="bg-gradient-to-r from-red-600 via-red-500 to-red-400 text-white px-4 py-2 rounded-lg"
                                        onClick={() => startTryOn()}>Start Try On
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showModal ? (
                        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <div
                                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

                                            <div
                                                className=" mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24"
                                                    stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                </svg>
                                            </div>

                                            <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                                                <div className="mt-2">
                                                    <form ref={formRef} className="">
                                                        <label
                                                            className="block mb-8 text-center  text-xl font-medium text-gray-900 dark:text-white"
                                                            htmlFor="file_input">Upload Image</label>

                                                        {/*<div className="flex items-center justify-center w-full">*/}
                                                        {/*    <label htmlFor="dropzone-file"*/}
                                                        {/*           className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">*/}
                                                        {/*        <div*/}
                                                        {/*            className="flex flex-col items-center justify-center pt-5 pb-6">*/}
                                                        {/*            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"*/}
                                                        {/*                 aria-hidden="true" xmlns="http://www.w3.org/2000/svg"*/}
                                                        {/*                 fill="none" viewBox="0 0 20 16">*/}
                                                        {/*                <path stroke="currentColor" stroke-linecap="round"*/}
                                                        {/*                      stroke-linejoin="round" stroke-width="2"*/}
                                                        {/*                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>*/}
                                                        {/*            </svg>*/}
                                                        {/*            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">*/}
                                                        {/*                <span className="font-semibold">Click to upload</span> or*/}
                                                        {/*                drag and drop</p>*/}
                                                        {/*            <p className="text-xs text-gray-500 dark:text-gray-400">SVG,*/}
                                                        {/*                PNG, JPG or JPEG Only</p>*/}
                                                        {/*        </div>*/}
                                                        {/*        <input id="dropzone-file" type="file" className="hidden"*/}
                                                        {/*               onChange={handleFileChange}/>*/}
                                                        {/*    </label>*/}
                                                        {/*</div>*/}

                                                        <input
                                                            className="lg:mx-0 sm:mx-2 text-sm w-full text-gray-900 border border-gray-300 rounded-lg
                                                cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none
                                                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                                            aria-describedby="file_input_help" id="file_input" type="file"
                                                            onChange={handleFileChange} />
                                                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                                                            id="file_input_help">PNG, JPG, Or JPEG Only</p>

                                                        {showFileUploadError ? (
                                                            <p className="mt-2 text-sm text-red-500 dark:text-red-300"
                                                                id="file_input_help">{fileUploadError}</p>) : (<div>
                                                                </div>)}
                                                    </form>

                                                    <div
                                                        className="block my-4  text-lg font-medium text-gray-900 dark:text-white">
                                                        Or
                                                    </div>

                                                    <div>
                                                        <input type="text"
                                                            onChange={(e) => setSelectedUrl(e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter image url" value={selectedUrl} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button type="button"
                                                onClick={uploadImage}
                                                className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Upload
                                            </button>
                                            <button type="button"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setSelectedUrl("");
                                                    setSelectedPersonFile("");
                                                    setSelectedClothFile("");
                                                    setModalType("");
                                                }}
                                                className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}

                    {isProcessing ? (
                        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <div
                                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <h3 className="text-lg font-semibold leading-6 text-gray-900"
                                                        id="modal-title">Performing virtual try on</h3>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500 my-4">Please wait...</p>

                                                        <div role="status">
                                                            <svg aria-hidden="true"
                                                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                viewBox="0 0 100 101" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor" />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill" />
                                                            </svg>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}
                </div>)
                :
                (<div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>)
            }
            </div>
        </div>
    );
}

export default VirtualTryOn;

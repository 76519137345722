import { Route,Routes } from "react-router-dom";
import VirtualTryOn from "./pages/VirtualTryOn";
import Login from "./pages/Login"
import Signup from "./pages/Signup"
import Profile from "./pages/Profile";
import Test from "./components/test"
import Navbar from "./components/Navbar";
import Cataloging from "./pages/Cataloging";
import Title from "./components/Title";
import LandingPage from "./pages/LandingPage";
import LandingPage2 from "./pages/LandingPage2";

const App = () => {
    return (
        <Routes>
            // <Route path="/tryon" element={<VirtualTryOn/>}/>
            // <Route path="/signup" element={<Signup/>}/>
            // <Route path="/login" element={<Login/>}/>
            // <Route path = "/profile" element = {<Profile/>} />
            <Route path ="/" element = {<LandingPage/>}/>
            // <Route path ="/cataloging" element = {<Cataloging/>}/>
            // <Route path = "/nav" element={<Navbar/>} />
            // <Route path="/landing" element ={<LandingPage2/>}/>
        </Routes>
    );
};

export default App;

import React from "react";
import Navbar from "../components/Navbar";
import landingModel from "../assets/landingModel1.png";
import landingGarment from "../assets/landingGarment.png";
import landingResult from "../assets/landingResult.png";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect,useLayoutEffect, useCallback, useState} from "react";
import garmentTop from "../assets/landingDemoTop.png";
import garmentBottom from "../assets/landingDemoBottom.png";
import model from "../assets/landingDemoModel.png";
import result from "../assets/landingDemoResult.png";
import arrowsvg from "../assets/Arrow.svg";
import landingbg1 from "../assets/bgGradient.svg";
import CardGradient from "../assets/CardGradient.svg";
import CardGradient2 from "../assets/CardGradient2.svg";
import githubicon from "../assets/giticon.svg";
import gitstar from "../assets/gitStar.svg";
import letterPost from "../assets/letterPost.svg";
import ForkIcon from "../assets/fork.svg";
import TryOnGraphic from "../assets/try-on-graphic.svg";
import TryOnGraphicMobile from "../assets/try-on-graphic-mobile.svg";
import ShopifyIcon from "../assets/ShopifyIcon.svg";
import mobileBgGrad from "../assets/mobile-bg-grad.svg";
import TryonLabsLogo from "../assets/TryonlabsLogoArrow.svg";
import TryonLabsLogoMobile from "../assets/TryonlabsLogoArrowMobile.svg";
import shopifyBannerSs1 from "../assets/shopify-extension-ss-1.svg";
import shopifyBannerSs2 from "../assets/shopify-extension-ss-2.svg";
import shopifyBannerSs3 from "../assets/shopify-extension-ss-3.svg";
import returnIcon from "../assets/returns-icon.svg";
import CatalogIcon from "../assets/CatalogIcon.svg";
import CSATIcon from "../assets/CSATIcon.svg";
import TimeIcon from "../assets/TimeIcon.svg";
import AOVIcon from "../assets/AOVIcon.svg";
import shopifyGradBg from "../assets/shopify-bg.svg";
import shopifyGradBgMobile from "../assets/shopify-bg-mobile.svg";
export default function LandingPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // console.log("authenticated in the landing page", authenticated);

    const [ethnicity, setEthnicity] = useState("white")
    const [gender, setGender] = useState("female")
    const [age, setAge] = useState(30)
    const [size, setSize] = useState('s')
    const [height, setHeight] = useState(152)
    const [githubStars, setGithubStars] = useState(0);
    const [forkCount, setForkCount] = useState(0)
    // console.log("authenticated in the landing page", authenticated)
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768); // Assuming "md" corresponds to a viewport width of 768px
        }

        handleResize(); // Initial call to set the mobile/desktop state

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Fetch GitHub stars
        const fetchGithubStars = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/tryonlabs/tryondiffusion');
                const data = await response.json();
                setGithubStars(data.stargazers_count);
                setForkCount(data.forks_count);
            } catch (error) {
                console.error('Error fetching GitHub stars:', error);
            }
        };

        fetchGithubStars();
    }, []);

    const Button = ({className, name, selected, onClick}) => {
        return <button
            className={`mr-2 my-1 capitalize rounded-full border-2 px-2 py-1 font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic bg-inherit pointer-events-none ${className}`}
            onClick={onClick}> {name} </button>;
    }


    const OptionGroup = ({title, options, selected, onSelect, className}) => (
        <div
            className={`flex flex-col flex-wrap w-full h-full bg-[#FFFFFFCC] border-1 mb-2 flex-shrink p-2 border-lite-red rounded-lg shadow-min-shadow ${className}`}>
            <span
                className="items-start justify-start font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic">{title}</span>
            <div className="flex flex-row flex-wrap w-full items-start">
                {options.map((option) => (
                    <Button
                        key={option}
                        selected={selected === option}
                        name={option}
                        onClick={() => onSelect(option)}
                    />
                ))}
            </div>
        </div>
    );

    // const checkAuthentication = useCallback(async () => {
    //     try {
    //         const userAuthenticated = await isAuthenticated();
    //         if (!userAuthenticated) {
    //             navigate("/login");
    //         }
    //     } catch (error) {
    //         console.error('Error checking authentication:', error);

    //         if (error.message && error.message.toLowerCase().includes('network')) {
    //             console.error('Network error, redirecting to login page');
    //             navigate("/login");
    //         }
    //     }
    // }, [dispatch, isAuthenticated, navigate]);

    // useEffect(() => {
    //     if (!authenticated) {
    //         checkAuthentication();
    //     }
    // }, []);

    const handleNavigateTryon = () => {
        navigate("/tryon")
    }

    return (

        <div className="h-full overflow-hidden w-full">
            {/* scrollbar scrollbar-track-transparent  */}
            <Navbar page="landing_page"/>

            <div className='h-full scrollbar items-center justify-start'  style={isMobile ? { backgroundImage: `url(${mobileBgGrad})`  } : {}}>
                {/* div 1 with headers and images */}
                <div className=" flex h-full w-full overflow-y-hidden sm:mt-[3rem] items-center justify-center p-4 "
                     style={isMobile ? {} :{ backgroundImage: `url(${landingbg1})`, backgroundSize: 'cover', backgroundPosition: 'center'  }} >
                        
                    <div className="max-w-7xl w-full h-full flex flex-col items-center justify-center mt-[4rem]">
                        <div className="flex flex-col w-full ">
                            <div className="grid md:grid-cols-2 gap-4 gird-rows-2">
                                <div className="flex flex-row items-start justify-center">
                                    <div aria-label="span-container" className="flex flex-col font-proxima not-italic items-center jusitfy-center text-center md:text-left md:items-start md:justify-center w-11/12 md:pl-8">

                                        <div className=" w-fit capitalize font-bold md:mb-[2rem] sm:mb-[1rem] mb-3 pointer-events-none ">
                                            <span className="capitalize font-bold md:text-5xl text-4xl leading-[2.75rem] md:leading-tight pointer-events-none ">Virtual Dressing Room for Apparels</span>
                                        </div>
                                        <span className="w-11/12 font-normal font-OpenSans md:text-base md:mb-10 sm:mb-6 mb-4 sm:text-sm text-md pointer-events-none">Enabling D2C Online Fashion Brands and E-Commerce Marketplaces to Leverage Generative AI to Provide a Stellar Experience to their Customers</span>
                                        <div className="flex items-center justify-center md:w-max pointer-events-auto">
                                            <a href="mailto:contact@tryonlabs.ai"><button className=" flex items-center justify-center md:w-max md:py-4  sm:py-3 py-2 px-[4rem] font-semibold sm:text-base text-xs leading-6 not-italic text-white border-1 bg-gradient-to-r from-[#F05941] to-[#BE3144] backdrop-blur-[50px] border-lite-red rounded-full">Get in touch with us</button></a>
                                        </div> 
                                    </div>

                                </div>
                                <div className="flex md:mt-0 mt-12">
                                    <img src={TryOnGraphic} className="h-full w-full object-contain object-top hidden sm:block md:mb-0 mb-9"></img>
                                    <img src={TryOnGraphicMobile} className="h-full w-full object-contain object-top block sm:hidden"></img>
                                </div>
                            </div>

                        </div>

                        <div className="flex flex-grow w-full h-full mb-24 ">
                          {/* bottom container of full width  */}
                            <div className="flex md:flex-row flex-col w-full md:h-full md:items-start md:justify-start items-center justify-center mt-52 px-4 md:px-0 ">
                                {/* contianer of grid with 3 cols */}
                                <div className="flex flex-col w-full h-full">
                                    <span className=" font-OpenSans font-bold text-5xl leading-[3.75rem] mb-16 z-10 md:text-left text-center">Why Tryon Labs</span>
                                    <div className="flex md:flex-row flex-col w-full md:h-full">
                                        <div className="flex flex-col items-center justify-center h-full md:w-1/3 w-full md:mr-8 z-10 ">
                                            {/* single card  */}
                                            <div className="flex flex-col md:items-start md:text-left md:justify-start items-center justify-center text-center bg-gradient-to-b from-[#F3F8F0] to-[#FADEDC] rounded-3xl h-full w-full p-8 mb-8 md:mb-0">
                                                <div className="flex bg-[#FADEDC] rounded-full items-center justify-center p-5 mb-8 ">
                                                    <img className="object-contain object-center w-auto h-auto " src={AOVIcon} />
                                                </div>
                                                <span className=" font-Mulish font-bold text-5xl mb-6">20-25%</span>
                                                <span className=" font-Mulish font-normal text-3xl">Increased AOV</span>
                                            </div>
                                        </div>

                                        {/* double card  */}
                                        <div className="flex flex-col md:flex-row items-center justify-center md:w-2/3 w-full md:mr-8 z-10 h-full">
                                        <div className="flex flex-col md:items-start md:text-left md:justify-start items-center justify-center text-center bg-gradient-to-b from-[#F3F8F0] to-[#CFE2F1] rounded-3xl h-full w-full md:w-1/2 p-8 mb-8 md:mr-8">
                                            <div className="flex bg-[#CFE2F1] rounded-full items-center justify-center p-5 mb-8 ">
                                                <img className="object-contain object-center w-auto h-auto " src={returnIcon} />
                                            </div>
                                            <span className=" font-Mulish font-bold text-5xl mb-6">5-10%</span>
                                            <span className=" font-Mulish font-normal text-3xl">Reduced Returns</span>
                                        </div>

                                        <div className="flex flex-col md:items-start md:text-left md:justify-start items-center justify-center text-center bg-gradient-to-b from-[#F3F8F0] to-[#D0ECE5] rounded-3xl h-full w-full md:w-1/2 p-8 mb-8 md:mr-8">
                                            <div className="flex bg-[#D0ECE5] rounded-full items-center justify-center p-5 mb-8 ">
                                                <img className="object-contain object-center w-auto h-auto " src={CSATIcon} />
                                            </div>
                                            <span className=" font-Mulish font-bold text-5xl mb-6">20-25%</span>
                                            <span className=" font-Mulish font-normal text-3xl">Increased CSAT</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            </div>
                            <div className="absolute md:left-0 -m-4 mb-24 md:flex bg-gradient-to-br from-[#F8CAC3] via-[#F8CAC3] to-[#F1D7FB] md:w-1/2 md:h-2/4 lg:h-2/3 2xl:h-3/6 w-full h-[calc(120vh)] sm:h-[calc(150vh-2rem)] md:rounded-3xl mt-28"> </div> 

                        </div>

                    </div>
                </div>

                <div className="flex h-fit backdrop-blur-[50px] bg-gradient-to-r from-[#F05941] to-[#BE3144] flex-shrink-0 items-center justify-center mb-[2rem] md:mb-0">
                    <span className=" font-OpenSans text-white md:text-4xl md:leading-[6rem] text-2xl leading-6 font-bold my-[1rem] sm:my-0">Our Tryon AI Engine</span>
                </div>

                {/* div 2 with virtual try on demo   */}
                <div className="flex flex-col h-full w-full overflow-y-hidden bg-cover bg-center items-center justify-center md:bg-[#FDF7FF] md:mb-[5rem] sm:mb-[2rem]">

                    <div className="max-w-7xl mx-auto h-full flex flex-col items-center justify-center md:p-2 -mt-2">

                        <div aria-labelledby="container-of-full-width" className="flex flex-col w-full md:flex-row overflow-y-auto items-center h-full ">

                            {/* <!-- Left Container --> */}
                            <div aria-labelledby="left-container-of-w-4/12" className="flex flex-wrap h-fit items-center justify-center w-full md:w-4/12 mt-6">
                                <div aria-label="span-container" className="flex flex-col font-OpenSans not-italic w-full items-center md:items-start text-center md:text-left justify-start pointer-events-none">
                                    <span className="w-full md:w-11/12 capitalize font-bold text-4xl sm:text-3xl md:text-5xl leading-6 text-[#22092C] mb-[0.75rem] md:mb-[2rem]">Virtual Try-On</span>
                                    <span className="w-10/12 md:w-9/12 font-normal text-md sm:text-base leading-6 mb-6 md:mb-12">Enhance your customer experience and engage them to your products using our virtual try-on.</span>
                                </div>
                            </div>

                            {/* <!-- Right Container --> */}
                            <div aria-labelledby="right-container-of-w-8/12" className="flex h-max w-full md:w-8/12 items-center md:items-start justify-start md:mx-6 xl:mx-0 mt-6 md:mt-0">
                                <div className="flex flex-grow w-full">
                                    
                                    {/* <div className="flex  w-full lg:w-max shadow-navbarShadow h-auto min-h-[22rem] pt-[2rem] md:px-[2rem] "> */}
                                    <div className="relative flex w-full pointer-events-none font-OpenSans">
                                        <div className="flex flex-col md:flex-row w-full h-max items-start p-[2rem] justify-normal">
                                            {/* <!-- Model Container --> */}
                                            <div className="flex flex-col w-full md:w-1/3 h-fit mr-0 md:mr-4 mb-4 md:mb-0 rounded-3xl bg-[#22092C] z-10">
                                                <div className="flex items-center rounded-3xl bg-gradient-to-b from-[#F9EAFF] to-white w-full h-[24rem] px-4 pt-3">
                                                    <img className="aspect-auto object-contain w-full h-full " src={landingModel} />
                                                </div>
                                                <div className="flex flex-col not-italic h-max items-start justify-start py-6 px-4">
                                                    <span className="text-[#FCFCFC] font-bold text-base leading-6 capitalize mb-4">Upload Your Picture</span>
                                                    <span className="text-[#F4C9D6] font-normal text-sm leading-5">Upload your picture or select from a collection of models</span>
                                                </div>
                                            </div>

                                            {/* <!-- Garment Container --> */}
                                            <div className="flex flex-col w-full md:w-1/3 h-max mr-0 md:mr-4 mb-4 md:mb-0 rounded-3xl bg-[#BE3144] z-10">
                                                <div className="flex items-center rounded-3xl bg-gradient-to-b from-[#FFE8E8] to-white w-full h-[24rem] px-4 pt-3">
                                                    <img className="aspect-auto object-contain object-center w-full h-full " src={landingGarment} />
                                                </div>
                                                <div className="flex flex-col not-italic items-start justify-start py-6 px-4">
                                                    <span className="text-[#FCFCFC] font-bold text-base leading-6 capitalize mb-4">Select The Product</span>
                                                    <span className="text-[#F4C9D6] font-normal text-sm leading-5">Select your a garment to perform virtual try-on</span>
                                                </div>
                                            </div>

                                            {/* <!-- Result Container --> */}
                                            <div className="flex flex-col w-full md:w-1/3 h-max rounded-3xl bg-[#F05941] z-10">
                                                <div className="flex items-center rounded-3xl bg-gradient-to-b from-[#FFE8E8] to-white w-full h-[24rem] px-4 pt-3">
                                                    <img className="aspect-auto object-contain w-full h-full " src={landingResult} />
                                                </div>
                                                <div className="flex flex-col not-italic items-start justify-start py-6 px-4">
                                                    <span className="text-[#FCFCFC] font-bold text-base leading-6 capitalize mb-4">Virtual Try-On</span>
                                                    <span className="text-[#F4C9D6] font-normal text-sm leading-5">Experience seamless warping of the garment around your body</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute flex bg-gradient-to-br top-0 from-[#F8CAC3] via-[#F8CAC3]  to-[#F1D7FB]  backdrop-blur-[50px] w-full h-[calc(100%-10rem)] md:h-[26rem]"></div>

                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>

                        </div>

                    </div>

                </div>



                
            </div>


            <div className=" flex w-full h-full overflow-hidden items-center justify-center backdrop-blur-[50px] bg-gradient-to-r from-[#F05941] to-[#BE3144] mb-[3rem]">

                <div className=' max-w-7xl mx-auto w-full h-full overflow-y-hidden '>

                    <div className=" grid grid-cols-1 md:grid-cols-7 w-full h-full font-OpenSans text-white items-center justify-center md:justify-between ">

                        {/* banner flex  */}
                        <div className=" md:col-span-3 flex w-full h-full items-center justify-center md:justify-between mb-[2rem] md:mb-0"  >

                            <div className=" grid grid-cols-1 md:grid-cols-2 items-center justify-center md:justify-between w-full h-full md:pl-8 mb-[2rem] md:mb-0 md:pb-0 " style={isMobile ? { backgroundImage: `url(${shopifyGradBgMobile})`, backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat' } : { backgroundImage: `url(${shopifyGradBg})`, backgroundSize: 'cover', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}    >
                                <div className="flex flex-col md:flex-row w-full h-full items-center justify-center md:justify-between md:px-[1rem] my-[2rem] md:mt-0 md:my-0 py-[3rem] md:py-[6rem]">
                                    <div className="flex flex-col font-OpenSans md:items-start items-center md:text-left text-center justify-center md:justify-start md:w-full w-full md:mt-0">
                                        <img src={ShopifyIcon} className="object-contain object-left-top mb-4 lg:h-[7.7rem] lg:w-[8.7rem] md:h-[5rem] md-w[5.5rem] h-[4.5rem] w-[5.5rem]" />
                                        <span className=" font-bold text-4xl leading-[3.5rem] md:text-[3rem] md:leading-[3.5rem] mb-4 w-3/4 md:w-full">Shopify App Extension</span>
                                        <span className=" font-normal text-base w-full">We bring the very first customer-facing Virtual Try-on Solution in apparels for D2C fashion brands</span>
                                        <a target="_blank" href="https://apps.shopify.com/tryonlabs-tryon-app"><button className=" flex items-center px-[2rem] justify-center mt-4 md:w-fit md:py-2 px-2 sm:py-3 py-2 font-bold sm:text-base text-sm leading-6 not-italic text-white border-1 bg-gradient-to-r from-[#95bf47] to-[#5e8e3e] backdrop-blur-[50px] border-lite-red rounded-full">Click Here To Install</button></a>
                                    </div>
                                </div>
                                <div className="relative flex items-center justify-center md:justify-end h-full w-full -mb-10 md:mb-0 md:ml-8">
                                    <img className="flex w-36 h-36 object-contain object-center " src={isMobile ? TryonLabsLogoMobile : TryonLabsLogo} alt="tryon labs logo" />
                                </div>
                            </div>
                        </div>


                        {/* md:scale-y-100 */}
                        {/* arrow flex  */}

                        {/* image flex  */}
                        <div className=" md:col-span-4 grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-items-center h-full w-full py-8 md:py-0 px-3 md:pr-10">
                            <img className=" w-auto h-auto object-center object-contain md:ml-4 mb-4 md:mb-0 " src={shopifyBannerSs1} />
                            <img className=" w-auto h-auto object-center object-contain md:ml-4 mb-4 md:mb-0 " src={shopifyBannerSs2} />
                            <img className=" w-auto h-auto object-center object-contain md:ml-4 mb-4 md:mb-0 " src={shopifyBannerSs3} />
                        </div>

                    </div>
                </div>
            </div>



            
            <div className="w-full h-full sticky bottom-0 flex flex-col items-center justify-center ">

                <div className="relative flex flex-col lg:flex-row items-center justify-center p-4 md:p-8 lg:p-[5rem]">
                    <div className="flex flex-col w-full md:w-[28rem] lg:w-[38rem] h-full min-h-[18.5rem] p-4 md:p-8 rounded-[2rem] mr-0 lg:mr-7"
                        style={{ backgroundImage: `url(${CardGradient})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                        <div className="flex flex-row items-center justify-start mb-4">
                            <img className="w-[3.5rem] h-[3.5rem] object-cover object-top" src={githubicon} />
                            <span className="ml-4 md:ml-6 font-OpenSans text-xl md:text-2xl font-semibold text-white">Github</span>
                        </div>
                        <div className="flex flex-col items-start justify-start font-OpenSans font-normal text-sm md:text-base text-[#FFF]">
                            <span className="mb-4">Abstract: Given two images depicting a person and a garment worn by another person, our goal is to generate a visualization of how the garment might look on the input person...</span>
                        </div>
                        <div className="flex md:flex-row flex-col sticky bottom-4 w-full mt-4 justify-between">
                            <div className="flex flex-row w-8/12 sm:max-w-5/12 sm:w-5/12 lg:w-3/12 justify-between">
                                <div className="flex flex-row items-center justify-center opacity-70 text-white mr-2 ">
                                    <img src={gitstar} className="w-8 h-8 mr-2" />
                                    <span>{githubStars}</span>
                                </div>
                                <div className="flex flex-row items-center justify-center opacity-70 text-white">
                                    <img src={ForkIcon} className="w-8 h-8 mr-2" />
                                    <span>{forkCount}</span>
                                </div>
                            </div>
                            <a className="flex items-center justify-center border-lite-red bg-gradient-to-r from-[#F05941] to-[#BE3144] text-white mr-2 my-2 capitalize rounded-full border-1 px-4 py-2 font-OpenSans text-sm font-normal leading-6 not-italic bg-inherit "
                                href="https://github.com/tryonlabs/tryondiffusion">view on github</a>
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:w-[28rem] lg:w-[38rem] h-full  min-h-[18.5rem] p-4 md:p-8 bg-[#BE3144] rounded-[2rem] mt-4 lg:mt-0"
                        style={{ backgroundImage: `url(${CardGradient2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="flex flex-row items-center justify-start mb-4">
                            <img className="w-[3.5rem] h-[3.5rem] object-cover object-top" src={letterPost} />
                            <span className="ml-4 md:ml-6 font-OpenSans text-xl md:text-2xl font-semibold text-white">Contact us</span>
                        </div>
                        <div className="flex flex-col items-start justify-start font-OpenSans font-normal text-sm md:text-base text-[#FFF]">
                            <span className="mb-4">If you want to leverage our Generative AI models, get in touch with us today</span>
                            <span className="mt-4 mb-2 font-semibold">Email</span>
                            <span className="flex w-min text-base opacity-70"><a href="mailto:contact@tryonlabs.ai">contact@tryonlabs.ai</a></span>
                        </div>
                    </div>
                </div>

                <span
                    className="h-full w-full min-h-[4rem] text-white font-openSans flex bg-[#22092C] items-center justify-center">Copyright © 2024 Tryon Labs. All rights reserved.</span>
            </div>

        </div>
    )
}
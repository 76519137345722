import React, { useEffect, useState, useRef, useCallback } from "react";
import Navbar from "../components/Navbar";
import calenderSvg from "../assets/calendar.svg";
import phoneSvg from "../assets/phone.svg";
import EditPencil from "../assets/EditPencil.svg"
import { selectAccessToken, selectAuthenticated, selectUserData } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { LEVITON_SERVER_URL } from "../config";
import { setUserID, setUserData, setAuthenticated } from "../store/AuthActions";
import useSessionStorage from "../store/useSessionStorage"
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import { clearData } from "../store/AuthActions";
import { useAuthentication } from "../Authentication";
import profile from "../assets/profile.svg"

export default function Profile() {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const accessToken = useSelector(selectAccessToken)
    const [user_data, setuserData] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fileInputRef = useRef(null);
    const [fileChange, setFileChange] = useState(null);
    const { isAuthenticated } = useAuthentication();
    const authenticated = useSelector(selectAuthenticated)
    const modalRef = useRef(null)

    const handleSvgClick = () => {
        fileInputRef.current.click();
    };

    if (!accessToken) {
        navigate("/login")
    }
    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const closeDropdown = () => {
        setDropdownVisible(false);
    };

    const handleOutsideClick = (e) => {
        if (isDropdownVisible && modalRef.current && !modalRef.current.contains(e.target)) {
            toggleDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isDropdownVisible]);

    function logout() {
        console.log("logout is called")
        dispatch(clearData());
        localStorage.clear();
        navigate("/login");
    }


    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/login");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                console.log("access in fetch data", accessToken)
                const response = await fetch(`${LEVITON_SERVER_URL}/api/v1/user/get/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const userid = data.id
                    dispatch(setUserID(userid));
                    const dataWithoutPassword = { ...data, password: undefined }; // Omit the password field
                    dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                    setuserData(dataWithoutPassword)
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);

            }
        };
        fetchUserData()
    },
        [accessToken, fileChange,])

    useEffect(() => {
        console.log("user data is updated", user_data)
    }, [user_data])

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('profile', file);

            try {
                console.log("access in fetch data", accessToken)
                const response = await fetch(`${LEVITON_SERVER_URL}/api/v1/user/update/`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                });

                if (response.ok) {
                    console.log('File uploaded successfully');
                    setFileChange(file)
                } else {
                    console.error('File upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };


    if (!user_data) {
        return (
            <div>
                <Navbar />
                <Spinner />
            </div>
        );
    }

    const formattedDate = new Date(user_data.created_on).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    console.log("user_data.profile", user_data.profile)

    return (
        <div>
            <Navbar page="profile" />
            <div className="flex w-full h-full items-center justify-center mt-[5rem]">
            {authenticated ?
                (
                    <div className="flex items-center justify-center h-full w-full">
                        <div className="flex m-4 items-center justify-center w-full h-full ">
                            <div
                                className="w-2/3 min-w-max h-max bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 relative">
                                <div className="flex flex-col items-center ">
                                    <div
                                        className="relative flex top-0 h-48 w-full border-gray-200 rounded-t-lg text-white bg-gradient-to-br from-blue-900 via-purple-600 to-blue-100 "></div>

                                    <div className="relative flex flex-row z-10 -mt-20 ">
                                        <img
                                            className="w-32 h-32 rounded-full p-1 m-2 bg-white object-cover object-top"
                                            // src="https://media.vogue.co.uk/photos/63611d51cf716662f02c80fb/1:1/w_2160,h_2160,c_limit/Selena_Photo%20brightened.jpg"
                                            src={user_data?.profile ? user_data.profile : profile}
                                        />
                                        <div onClick={handleSvgClick}
                                            className="relative -left-12 top-20 w-8 h-8 p-1 rounded-full m-2 border-2 bg-white">
                                            <img src={EditPencil} className="cursor-pointer" />
                                        </div>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                    </div>

                                    <div className="flex w-full h-max justify-end -mt-12 mr-6">
                                        <div className="flex items-center space-x-3 relative">
                                            <button
                                                type="button"
                                                onClick={toggleDropdown}
                                                className="flex text-sm"
                                                id="user-menu-button"
                                                aria-expanded={isDropdownVisible}
                                                onFocus={closeDropdown}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                        stroke="#121212" strokeOpacity="0.5" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                                        stroke="#121212" strokeOpacity="0.5" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                                        stroke="#121212" strokeOpacity="0.5" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </button>
                                            {isDropdownVisible && (
                                                <div
                                                    className=" z-50 absolute top-6 right-2 w-max text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow">
                                                    <div >
                                                        <ul ref={modalRef} className="py-2" aria-labelledby="user-menu-button">
                                                            <li>
                                                                <a href="/"
                                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                                    Try on
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="src/components#"
                                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                                    Edit
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/src/pages/Signup"
                                                                    className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100">
                                                                    Delete
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <a href="src/components#"
                                                                        className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100"
                                                                        onClick={logout}>
                                                                        Log out
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex font-sans flex-col items-center relative mt-10">
                                    <span
                                        className="text-2xl text-black font-medium "> {user_data.first_name + " " + user_data.last_name} </span>
                                    <span className="text-normal text-gray-400 font-normal">
                                        <a> {`@${user_data.username}`}</a>
                                    </span>
                                    <div className="flex flex-row justify-between m-2 w-5/12">
                                        <div
                                            className="flex flex-row p-4 items-center justify-center text-normal text-gray-400 font-normal">
                                            <img className="h-4 w-4 m-2" src={calenderSvg} alt="Calendar icon" />
                                            <div className="flex flex-col">
                                                <span>Joined on</span>
                                                <span> {`${formattedDate}`}</span>
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-row p-3 items-center justify-center text-normal text-gray-400 font-normal">
                                            <img className="h-4 w-4 m-2" src={phoneSvg} alt="Calendar icon" />
                                            <div className="flex flex-col">
                                                <span>Contact </span>
                                                <span> {user_data.mobile_no}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                :
                (<div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>)
            }
            </div>
        </div>
    );
}

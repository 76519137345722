import React, { useEffect, useState, useRef, useCallback,Suspense,lazy } from "react";
import { useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import { useAuthentication } from "../Authentication";
import { useNavigate } from "react-router-dom";
import GradientSvg from "../assets/Gradient.svg"
import FileUploadSvg from "../assets/FileUpload.svg";
import pi1 from "../assets/pe1.jpeg";
import { v4 as uuidv4 } from 'uuid';
import product1 from "../assets/product.jpeg";
import close from "../assets/close.svg";
import check from "../assets/check.svg";
import defMale from "../assets/defMale.jpg";
import defFemale from "../assets/defFemale.jpg";
import axios from "axios";
import { LEVITON_FRONTEND_URL, LEVITON_SERVER_URL } from "../config";
import { useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import EnlargeIcon from '../assets/enlarge.svg'
import SkeletonLoader from "../components/SkeletonLoader";
import { setAuthenticated } from "../store/AuthActions";
import PersonImage5 from "../assets/personimage5.jpg"
import ClothImage1 from "../assets/clothimage1.jpg"
import { getFirstDayOfTheMonth } from "flowbite-react/lib/esm/components/Datepicker/helpers";

const GENERATE_FASHION_MODEL_URL = `${LEVITON_SERVER_URL}/api/v1/fashion_model/`
const LazyImage = lazy(() => import('../components/LazyImage'));


const PROCESS_URL = `${LEVITON_SERVER_URL}/api/v1/process/` ;
const PROCESS_RESULT_URL = `${LEVITON_SERVER_URL}/api/v1/process/results?process_id=` ;


export default function Cataloging() {
    const dispatch = useDispatch()
    const authenticated = useSelector(selectAuthenticated)
    const [filter, setFilter] = useState("female");
    const [genModel, setGenModel] = useState(false);
    const accessToken = useSelector(selectAccessToken);
    const [FdFemale, setFdFemale] = useState([])
    const [FdMale, setFdMale] = useState([])
    const [maleModels, setMaleModels] = useState([]);
    const [femaleModels, setFemaleModels] = useState([]);
    const [models, setModels] = useState([]);
    const modelInputRef = useRef(null);
    const productInputRef = useRef(null);
    const [generatedModel, setGeneratedModel] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [showImageViewer, setShowImageViewer] = useState(false)
    const [selectedImageInViewer, setSelectedImageInViewer] = useState("")
    const [maleGarments, setMaleGarments] = useState([]);
    const [femaleGarments, setFemaleGarments] = useState([])

    const [products, setProducts] = useState([])

    const [gModels, setGModels] = useState([

    ])
    const { isAuthenticated } = useAuthentication();
    const navigate = useNavigate();
    
    const [selectedModel, setSelectedModel] = useState({id:-1 , src : defFemale});
    const [selectedProduct, setSelectedProduct] = useState({id:-1 , src : defMale});
    const [selectedPersonFile, setSelectedPersonFile] = useState("")
    const [selectedClothFile, setSelectedClothFile] = useState("")
    const [selectedImages, setSelectedImages] = useState([]);

    const [loadedModels, setLoadedModels] = useState(false);
    const [loadedProducts, setLoadedProducts] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState('model');
    const [previewGrid, setPreviewGrid] = useState([]);
    const modalRef = useRef(null);
    const previewRef = useRef(null);
    const isMounted = useRef(false);


    useEffect(() => {
        setPreviewGrid(selectedPreview === 'model' ? models : products);
    }, [showImageViewer, selectedPreview])

    useEffect(() => {
        const fetchData = async () => {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data',
            };

            try {
                console.log('Fetching data...');
                
                try {

                    const maleModelsResponse = await axios.get(`${LEVITON_SERVER_URL}/api/v1/static/image/?gender=male&type=model`, { headers });
                    const maleModelsData = maleModelsResponse.data.image_urls.map((item) => ({
                        id: uuidv4(),
                        src: `${LEVITON_SERVER_URL}${item}`,
                    }));
                    setMaleModels(maleModelsData);
                    console.log("male models", maleModels)

                    // Fetch Female Models
                    const femaleModelsResponse = await axios.get(`${LEVITON_SERVER_URL}/api/v1/static/image/?gender=female&type=model`, { headers });
                    const femaleModelsData = femaleModelsResponse.data.image_urls.map((item) => ({
                        id: uuidv4(),
                        src: `${LEVITON_SERVER_URL}${item}`,
                    }));
                  
                    setFemaleModels(femaleModelsData);

                    // Fetch Male Garments
                    const maleGarmentsResponse = await axios.get(`${LEVITON_SERVER_URL}/api/v1/static/image/?gender=male&type=product`, { headers });
                    const maleGarmentsData = maleGarmentsResponse.data.image_urls.map((item) => ({
                        id: uuidv4(),
                        src: `${LEVITON_SERVER_URL}${item}`,
                    }));
                    setMaleGarments(maleGarmentsData);
                    console.log("male garments", maleGarments)

                    // Fetch Female Garments
                    const femaleGarmentsResponse = await axios.get(`${LEVITON_SERVER_URL}/api/v1/static/image/?gender=female&type=product`, { headers });
                    const femaleGarmentsData = femaleGarmentsResponse.data.image_urls.map((item) => ({
                        id: uuidv4(),
                        src: `${LEVITON_SERVER_URL}${item}`,
                    }));
                    setFemaleGarments(femaleGarmentsData);
                

                } catch (error) {
                    console.error("unable to fetch the static files", error)
                }

                const response = await axios.get(`${LEVITON_SERVER_URL}/api/v1/myimage/`, { headers });

                const transformedData = response.data.map((item) => ({
                    id: item.id,
                    src: item.image,
                    type: item.type,
                    gender: item.gender,
                }));

                const transformedDataMaleModels = transformedData
                    .filter((item) => item.type === 'model' && item.gender === 'male');

                const transformedDataFemaleModels = transformedData
                    .filter((item) => item.type === 'model' && item.gender === 'female');

                const transformedDataMaleGarments = transformedData
                    .filter((item) => item.type === 'garment' && item.gender === 'male');

                const transformedDataFemaleGarments = transformedData
                    .filter((item) => item.type === 'garment' && item.gender === 'female');


                setMaleModels((prevModels) => [...transformedDataMaleModels, ...prevModels]);
                setFemaleModels((prevModels) => [...transformedDataFemaleModels, ...prevModels]);
                setMaleGarments((prevGarments) => [...transformedDataMaleGarments, ...prevGarments]);
                setFemaleGarments((prevGarments) => [...transformedDataFemaleGarments, ...prevGarments]);

                // setSelectedModel(femaleModels[0])
                // console.log("female models selected", selectedModel)
                // setSelectedProduct(femaleGarments[0])
                // console.log("female garments selected", selectedProduct)


                console.log("Transformed  Data:", transformedData);

                console.log("Transformed Male Model Data:", maleModels);
                console.log("Transformed Female Model Data:", femaleModels);
                console.log("Transformed Male Garment Data:", maleGarments);
                console.log("Transformed Female Garment Data:", femaleGarments);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch data only when the component mounts
        if (!isMounted.current) {
            fetchData();
            isMounted.current = true; // Set isMounted to true after the first render
        }
    }, [accessToken, filter]);

    useEffect(() => {
        // Update the state based on the current filter
        if (filter === "male") {
            setModels(maleModels);
            setProducts(maleGarments)
            setSelectedModel(maleModels[0]);
            setGeneratedModel(defMale);
            setSelectedPersonFile("")
            setSelectedClothFile("")  
        } else {
            setModels(femaleModels);
            setProducts(femaleGarments)
            setSelectedModel(femaleModels[0]);
            setGeneratedModel(defFemale);
            setSelectedPersonFile("")
            setSelectedClothFile("")  
        } 

    }, [filter, maleModels, femaleModels]);


    useEffect(() => {
        if (models.length !== 0) {
            setLoadedModels(true)
        }
        if (products.length !== 0) {
            setLoadedProducts(true)
        }
    }, [models, products,])


    const handleSelectModel = (img) => {
        setSelectedModel(img);
        setSelectedPersonFile("")
        console.log("selected model", selectedModel, selectedPersonFile)

    }

    const triggerModelAdd = () => {
        modelInputRef.current.click()
    }

    useEffect(() => {
        console.log("Gmodels", gModels)
    }, [models,])



    const handleModelUpload = async (file, url, product) => {
        try {
            const formData = new FormData();

            formData.append('gender', filter);
            if (!product) {
                formData.append('type', "model")
            } else {
                formData.append('type', "garment")
            }
            if (file) {
                formData.append('image', file);
            } else if (url) {
                formData.append('file_url', url);
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data', // Important for file uploads
            };

            const response = await axios.post(`${LEVITON_SERVER_URL}/api/v1/myimage/save/`, formData, { headers });
            console.log('POST request successful:', response.data);
            return { response: response.data, status: true };
        } catch (error) {
            console.error("Error making POST request:", error);
            return { response: null, status: false };
        }
    }

    const handleModelChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const response = await handleModelUpload(file, null, false);

            if (response.status) {// Assuming filter is a state variable

                const newModel = {
                    id: response.response.id, // Use id from the response data
                    src: URL.createObjectURL(file),
                };

                // Update the state based on the current filter
                if (filter === 'male') {
                    setMaleModels((prevModels) => [newModel, ...prevModels]);
                } else {
                    setFemaleModels((prevModels) => [newModel, ...prevModels]);
                }
            } else {
                console.error("Can't upload image to the server");
            }
        }
    };


    const triggerProductAdd = () => {
        productInputRef.current.click()
    }

    const handleProductChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const response = await handleModelUpload(file, null, true);

            if (response.status) {// Assuming filter is a state variable

                const newProduct = {
                    id: response.response.id, // Use id from the response data
                    src: URL.createObjectURL(file),
                };

                // Update the state based on the current filter
                setProducts((prevModels) => [newProduct, ...prevModels]);
            } else {
                console.error("Can't upload image to the server");
            }
        }
    };

    const handleSelectProduct = (img) => {
        setSelectedProduct(img);
        setSelectedClothFile("");
        console.log("selected product is ", selectedProduct,selectedClothFile)
    }

    const toggleFilter = (value) => {
        setFilter(value)
        console.log("filter is changes")
    }

    const toggleGenModel = () => {
        setGenModel(!genModel)
        if (!genModel) {
            handleGenerateFashionModel()
        }
    }

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            toggleGenModel();
        }
    };

    const handleOutsidePreviewClick = (e) => {
        if (previewRef.current && !previewRef.current.contains(e.target)) {
            setShowImageViewer(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [toggleGenModel,]);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsidePreviewClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsidePreviewClick);
        };
    }, [setShowImageViewer,]);

    const handleImageSelect = (img) => {
        if (selectedImages.some((selectedImg) => selectedImg.id === img.id)) {
            setSelectedImages(selectedImages.filter((selectedImg) => selectedImg.id !== img.id));
        } else {
            setSelectedImages([...selectedImages, img]);
        }
    };

    const handleSaveModel = async () => {
        const uploadPromises = selectedImages.map(async (model) => {
            
            const response = await handleModelUpload(null, model.src, false); // Assuming each selected image has a 'file' property

            console.log("response from save model", response)
            if (response.status) {
                const newModel = {
                    id: response.response.id, // Use id from the response data
                    src: model.src,
                };

                // Update the state based on the current filter
                if (filter === 'male') {
                    setMaleModels((prevModels) => [newModel, ...prevModels]);
                } else {
                    setFemaleModels((prevModels) => [newModel, ...prevModels]);
                }

                return newModel; // Return the newModel for further processing, if needed
            } else {
                console.error("Failed to upload image to the server");
                return null;
            }
        });

        const uploadedModels = await Promise.all(uploadPromises);

        const successfulModels = uploadedModels.filter((model) => model !== null);

        setSelectedImages([]);
        toggleGenModel();
    };



    const handleExportModel = () => {
        const imageUrl = selectedModel.src;
        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = 'selected_model_image.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        console.log("Female models changed", femaleModels)
    }, [femaleModels,])

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/login");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, [accessToken,]);


    const findImageIndex = (selectedImage) => {
        return models.findIndex((img) => img.id === selectedImage.id);
    };

    const handleSelectedImageInViewer = (selectedImage) => {
        const currentIndex = findImageIndex(selectedImage);
        const models = filter === 'male' ? maleModels : femaleModels;
        const previousIndex = (currentIndex - 1 + models.length) % models.length;
        const nextIndex = (currentIndex + 1) % models.length;

        const selectedModel = models[currentIndex];
        const previousModel = currentIndex === 0 ? null : models[previousIndex];
        const nextModel = currentIndex === models.length - 1 ? null : models[nextIndex];

        setShowImageViewer(true);
        setSelectedImageInViewer({
            selectedModel,
            previousModel,
            nextModel,
        });
    };

    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    });


    const handleGenerateFashionModel = () => {
        setIsProcessing(true);
        let data = new FormData();

        axios.post(GENERATE_FASHION_MODEL_URL + `?gender=${filter}&no=5`, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                // handle success
                console.log(response);
                if (response.data.status === "success") {
                    let process_id = response.data.process_id;
                    console.log(process_id);
                    let result_url = [
                        GENERATE_FASHION_MODEL_URL + "?process_id=" + process_id + "&image_id=0",
                        GENERATE_FASHION_MODEL_URL + "?process_id=" + process_id + "&image_id=1",
                        GENERATE_FASHION_MODEL_URL + "?process_id=" + process_id + "&image_id=2",
                    ];

                    console.log("result", result_url)
                    setIsProcessing(false);

                    const newModels = result_url.map(url => ({ id: uuidv4(), src: url }));

                    setGModels((prevModels) => [...prevModels, ...newModels]);
                } else {
                    console.log(response.data.message);
                    setIsProcessing(false);
                }
            })
            .catch((error) => {
                // handle error
                console.log(error);
                setIsProcessing(false);
            });
    };

    const handleGenerateModel = () => {

    }



// Your startTryOn function remains the same
function startTryOn() {
    console.log("Selected person image:", selectedModel.src, selectedProduct.src);
    setIsProcessing(true);
    let data = new FormData();

    fetchAndAppendToFormData(selectedModel.src, 'person_image', data)
        .then(() => fetchAndAppendToFormData(selectedProduct.src, 'cloth_image', data))
        .then(() => {
            axios.post(PROCESS_URL, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // handle success
                console.log(response);
                if (response.data.status === "success") {
                    let process_id = response.data.process_id;
                    let result_url = PROCESS_RESULT_URL + process_id;
                    setGeneratedModel(result_url);
                } else {
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                // handle error
                console.log(error);
            })
            .finally(() => {
                setIsProcessing(false);
            });
        })
        .catch((error) => {
            // handle error during fetching images
            console.log(error);
            setIsProcessing(false);
        });
}

async function fetchAndAppendToFormData(url, fieldName, formData) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors', // Set mode to no-cors
      });
  
      if (response.ok) {
        console.log(`Fetched ${fieldName} successfully`);
        const blob = await response.blob();
        let filename = url.replace(/^.*[\\/]/, '');
  
        console.log(`Fetched ${fieldName} blob:`, blob);
        console.log(`Filename for ${fieldName}:`, filename);
  
        formData.append(fieldName, blob, filename);
      } else {
        throw new Error(`Error fetching ${fieldName} image: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fd fetching ${fieldName} image:`, error); // Log the entire error object
      throw new Error(`Error fetching ${fieldName} image: ${error.message}`);
    }
  }
  
  



// function startTryOn() {
//     console.log("Selected person image:", selectedModel.src, selectedProduct.src);
//     setIsProcessing(true);
//     let data = new FormData();

//     fetchAndAppendToFormData(selectedModel.src, 'person_image', data)
//         .then(() => fetchAndAppendToFormData(selectedProduct.src, 'cloth_image', data))
//         .then(() => {
//             axios.post(PROCESS_URL, data, {
//                 headers: {
//                     Authorization: `Bearer ${accessToken}`,
//                     'Content-Type': 'multipart/form-data'
//                 }
//             })
//             .then((response) => {
//                 // handle success
//                 console.log(response);
//                 if (response.data.status === "success") {
//                     let process_id = response.data.process_id;
//                     let result_url = PROCESS_RESULT_URL + process_id;
//                     setGeneratedModel(result_url)
                
//                 } else {
//                     console.log(response.data.message);
//                 }
//             })
//             .catch((error) => {
//                 // handle error
//                 console.log(error);
//             })
//             .finally(() => {
//                 setIsProcessing(false);
//             });
//         })
//         .catch((error) => {
//             // handle error during fetching images
//             console.log(error);
//             setIsProcessing(false);
//         });
// }

// async function fetchAndAppendToFormData(url, fieldName, formData) {
//     try {
//         const response = await fetch(url);
//         if (!response.ok) {
//             throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
//         }

//         const contentType = response.headers.get('content-type');
//         const blob = await response.blob();

//         let filename = url.replace(/^.*[\\/]/, '');

//         // If possible, use a library like 'file-type' to determine MIME type
//         // For simplicity, I'm using a basic mapping based on the file extension
//         const mimeTypes = {
//             'jpg': 'image/jpeg',
//             'jpeg': 'image/jpeg',
//             'png': 'image/png',
//             'gif': 'image/gif',
//             // Add more mappings as needed
//         };
//         const extension = filename.split('.').pop().toLowerCase();
//         const mimeType = mimeTypes[extension] || contentType || 'application/octet-stream';

//         console.log(`Fetched ${fieldName} blob:`, blob);
//         console.log(`Filename for ${fieldName}:`, filename);

//         formData.append(fieldName, blob, filename);

//     } catch (error) {
//         throw new Error(`Error fetching ${fieldName} image: ${error.message}`);
//     }
// }






    
    
    return (

        <div className=" h-full overflow-y-hidden bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url(${GradientSvg})` }}>

            <Navbar page ="catalogue_generation" />
            <div className="h-full w-full items-center justify-center mt-[4rem]">
            <div className={`h-[calc(100vh-4rem)]  ${genModel ? ' overflow-hidden' : ' overflow-y-auto'}`}>

                {authenticated ?
                    (<div>
                        <div className="max-w-7xl mx-auto w-screen">
                            {/* Gradeint goes here */}

                            {/* Model and Product div */}
                            <div className="flex md:flex-row flex-col max-xl items-between w-full lg:w-fit xl:w-full ">

                                <div className="md:w-8/12 sm:w-fit flex flex-col mr-16 mt-20 -mb-20 font-OpenSans pl-6">
                                    <span className=" text-4xl font-semibold leading-8 md:w-11/12 ">Generate Models Using Our Novel AI</span>
                                    <span className=" font-normal text-base normal-case mt-6 mb-8 mr-6 md:w-11/12">Select the best AI generated models according to your product requirement and generate catalog in different poses.</span>
                                    <div className="flex flex-col mb-20 mr-10 ">
                                        {/* Filter head */}
                                        <div className="flex justify-between items-center h-min ">

                                            <div className="flex flex-row justify-start font-OpenSans">
                                                <button
                                                    className={filter === "female" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : ' rounded-full border-2 border-lite-red bg-[#FCEFEF66] bg-opacity-40  normal-case font-normal text-sm px-4 py-2 m-1'}
                                                    onClick={() => toggleFilter("female")}> Female
                                                </button>
                                                <button
                                                    className={filter === "male" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : 'rounded-full border-2 border-lite-red bg-[#FCEFEF66] bg-opacity-40  normal-case font-normal text-sm px-4 py-2 m-1'}
                                                    onClick={() => toggleFilter("male")}> Male
                                                </button>
                                            </div>
                                            <div className="flex sm:justify-end sm:items-end flex-shrink w-fit ">
                                                <button onClick={(e) => {
                                                    toggleGenModel()
                                                    e.preventDefault()
                                                }}
                                                    className="border-2 bg-inherit border-selected text-selected rounded-full normal-case font-semibold text-sm px-4 py-2">Generate More
                                                </button>
                                            </div>
                                        </div>
                                        {/* Model Section */}
                                        <div className="flex mt-4 overflow-y-auto h-[35rem] max-xl overflow-x-hidden flex-wrap w-fit">
                                            {loadedModels ? (
                                                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-4 gap-3 md:h-[43rem] h-[10rem] flex-wrap ">
                                                    <div onClick={triggerModelAdd} className="p-2 h-[8rem] w-auto md:w-44 md:h-[21rem] cursor-pointer bg-opacity-40 bg-[#FCEFEF66] border-2 border-lite-red hover:border-selected aspect-square rounded-2xl">
                                                        <div className="flex flex-col bg-opacity-50 bg-[#FDFDFD8A] items-center justify-center w-full h-full object-cover rounded-lg">
                                                            <img className="flex mt-8 mb-4" src={FileUploadSvg} alt="file upload" />
                                                            <p className="w-8/12 mx-8 mb-8 text-center text-selected font-normal normal-case text-sm font-OpenSans">
                                                                Add your model photo
                                                            </p>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            ref={modelInputRef}
                                                            onChange={handleModelChange}
                                                        />
                                                    </div>

                                                    {models.map((img) => (
                                                        <div
                                                            key={img.id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleSelectModel(img);
                                                            }}
                                                            className={`p-2 relative h-[8rem] w-auto md:w-44 md:h-[21rem] cursor-pointer bg-opacity-40  bg-[#FCEFEF66] border-2 border-lite-red rounded-2xl ${selectedModel.id === img.id ? 'border-2 border-solid border-selected' : ''}`}
                                                        >
                                                            <Suspense fallback={<div>Loading</div>}>
                                                            <img
                                                                onClick={() => {
                                                                    setShowImageViewer(true);
                                                                    setSelectedPreview('model')
                                                                    handleSelectedImageInViewer(img);
                                                                }}
                                                                className="absolute top-4 right-4 w-4 h-4 cursor-pointer"
                                                                src={EnlargeIcon}
                                                                alt="enlarge icon"
                                                            />
                                                            <img
                                                                src={img.src}
                                                                className="w-full h-full aspect-auto object-top object-cover rounded-lg"
                                                                alt={`Image ${img.id}`}
                                                                placeholder={img.src} // You may need to provide a placeholder
                                                            />
                                                            </Suspense>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                                : (
                                                    <div className="flex flex-wrap h-min -ml-2">
                                                        {Array.from({ length: 8 }, (_, index) => (
                                                            <div key={index} className="flex">
                                                                <SkeletonLoader />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                        </div>

                                        {/* Product section */}
                                        <div className="flex flex-col mt-20 ">
                                            <span  className=" text-4xl font-semibold leading-8 ">Try it out on product</span>
                                            <span className=" font-normal text-base normal-case mt-6 mb-8 mr-6">Select the product photo which is wants to try on your catalog as AI model.</span>
{/* h-[calc(100vh-14rem)] */}
                                            <div className="flex mt-4 overflow-y-auto h-[35rem] max-xl overflow-x-hidden w-fit ">
                                                {loadedProducts ? (
                                                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-4  gap-3 md:h-[43rem] h-[10rem] flex-wrap ">
                                                        <div onClick={triggerProductAdd} className="p-2 h-[8rem] w-auto md:w-44 md:h-[21rem] cursor-pointer bg-opacity-40 bg-[#FCEFEF66] border-2 border-lite-red hover:border-selected aspect-square rounded-2xl">
                                                            <div className="flex flex-col bg-opacity-50 bg-[#FDFDFD8A] items-center justify-center w-full h-full object-cover rounded-lg">
                                                                <img className="flex mt-8 mb-4" src={FileUploadSvg} alt="file upload" />
                                                                <p className="mx-8 mb-8 text-center text-selected font-normal normal-case text-sm font-OpenSans">
                                                                    Add your product photo
                                                                </p>
                                                            </div>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                ref={productInputRef}
                                                                onChange={handleProductChange}
                                                            />
                                                        </div>
                                                        {products.map((img) => (
                                                            <div
                                                                key={img.id}
                                                                onClick={() => handleSelectProduct(img)}
                                                                className={`p-2 relative h-[8rem] w-auto md:w-44 md:h-[21rem] cursor-pointer bg-opacity-40  bg-[#FCEFEF66] border-2 border-lite-red rounded-2xl ${selectedProduct.id === img.id ? 'border-2 border-solid border-selected' : ''}`}
                                                            >
                                                                <Suspense fallback={<div>Loading...</div>}>
                                                                <LazyImage
                                                                    onClick={() => {
                                                                        setShowImageViewer(true);
                                                                        setSelectedPreview('product')
                                                                        setSelectedImageInViewer(img);
                                                                    }}
                                                                    className="absolute top-4 right-4 w-4 h-4 cursor-pointer"
                                                                    src={EnlargeIcon}
                                                                    alt="enlarge icon"
                                                                />
                                                                </Suspense>
                                                                <img
                                                                    src={img.src}
                                                                    className="w-full h-full aspect-auto object-top object-cover rounded-lg"
                                                                    alt={`Image ${img.id}`}
                                                                    placeholder={img.src} // You may need to provide a placeholder
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                                    : (
                                                        <div className="grid grid-cols-4 -ml-2 flex-wrap h-min">
                                                            {Array.from({ length: 8 }, (_, index) => (
                                                                <div key={index} className="flex">
                                                                    <SkeletonLoader />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* preview section */}
                                <div className="flex flex-col md:w-4/12 w-fit min-w-fit items-end md:block sm:hidden ">
                                    <div className="md:fixed sm:relative w-max h-full xl:right-auto md:right-[1rem]">
                                        <div className=" flex flex-col items-center justify-center right-0 h-[calc(100vh-5rem)] min-h[5rem] ">
                                            <div
                                            // sm:w-[8rem] md:w-[18rem] xl:w-[24rem]
                                                className="flex p-2 w-fit h-max max-xl bg-opacity-40 bg-[#FCEFEF66] border-2 rounded-2xl border-lite-red m-4">
                                                <img className="rounded-2xl sm:w-[8rem] md:w-[18rem] xl:w-[24rem] h-auto max-h-[calc(100vh-12rem)] bg-white aspect-auto object-top " src={generatedModel} />
                                            </div>
                                            <div
                                                className="flex flex-row w-full mx-auto items-center justify-center text-white font-OpenSans text-sm font-normal normal-case">
                                                <button onClick={() => startTryOn()}
                                                    className=" py-2 px-4 border-1 bg-selected hover:bg-[#F05941] backdrop-blur-2xl border-lite-red rounded-2xl mr-5">Generate
                                                    Model
                                                </button>
                                                <button onClick={() => handleExportModel()}
                                                    className="py-2 px-4 border-1 bg-selected hover:bg-[#F05941] backdrop-blur-2xl border-lite-red rounded-2xl">Export
                                                    Model
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {/* Generate Model Section */}
                                {genModel ?
                                    (
                                        <div
                                            className="absolute flex items-end justify-end right-0 bottom-0 bg-opacity-20 backdrop-blur-xl w-full h-[calc(100vh-4rem)] overflow-hidden">
                                            <div className={` bg-opacity-20 backdrop-blur-xl h-full `}>

                                                <div
                                                    className={`flex items-end justify-end ${genModel ? 'animate-in slide-in-from-right-1/3' : 'animate-out slide-out-to-right-3/4'}`}>

                                                    <div ref={modalRef}
                                                        className=" bg-white shadow-navbarShadow w-full h-[calc(100vh-4rem)] ">
                                                        <div
                                                            className=" flex flex-col justify-start items-start h-[calc(100vh-8rem)] mx-auto font-OpenSans w-11/12">
                                                            <div
                                                                className="flex flex-row items-start justify-between w-full ">
                                                                <span
                                                                    className=" mt-6 text-4xl font-semibold leading-6">Generate Model</span>
                                                                <img onClick={() => toggleGenModel()}
                                                                    className=" w-6 h-6 mt-6" src={close} />
                                                            </div>
                                                            <span className=" font-normal text-base normal-case mt-5 ">Choose generated model which is suitable for your product.</span>
                                                            <div className="flex flex-col mt-5 bg-white h-full mx-auto ">
                                                                <div className="flex justify-between items-center w-10/12  h-min">
                                                                    <div
                                                                        className="flex flex-row justify-start font-OpenSans ">
                                                                        <button
                                                                            className={filter === "female" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : ' rounded-full border-2 border-lite-red bg-[#FCEFEF66] bg-opacity-40  normal-case font-normal text-sm px-4 py-2 m-1'}
                                                                            onClick={() => toggleFilter("female")}> Female
                                                                        </button>
                                                                        <button
                                                                            className={filter === "male" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : 'rounded-full bg-inherit border-2 border-lite-red  normal-case font-normal text-sm px-4 py-2 m-1'}
                                                                            onClick={() => toggleFilter("male")}> Male
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="flex mt-5 mx-auto items-start justify-center flex-shrink ">

                                                                    <div className="grid grid-cols-4 gap-6 min-h-[25rem] h-[calc(100vh-16rem)] overflow-y-auto pb-12 px-4 flex-shrink pr-6">

                                                                        {gModels.map((img) => (
                                                                            <div
                                                                                key={img.id}
                                                                                className={`p-2 w-[8rem] h-[16rem] cursor-pointer relative rounded-2xl ${selectedImages.some((selectedImg) => selectedImg.id === img.id) ? 'border-2 border-selected' : 'border-2 border-lite-red'}`}
                                                                                onClick={() => handleImageSelect(img)}
                                                                            >
                                                                                <Suspense fallback={<div>Loading...</div>}>
                                                                                <LazyImage
                                                                                    src={img.src}
                                                                                    className={`w-full h-full aspect-auto object-cover object-top rounded-lg ${selectedImages.some((selectedImg) => selectedImg.id === img.id) ? 'opacity-40' : ''}`}
                                                                                    alt={`Image ${img.id}`}
                                                                                    placeholder={img.src} // You may need to provide a placeholder
                                                                                />
                                                                                </Suspense>

                                                                                {selectedImages && selectedImages.some((selectedImg) => selectedImg.id === img.id) && (
                                                                                    <div
                                                                                        className="absolute inset-4 justify-end object-cover flex">
                                                                                        <img className="w-4 h-4"
                                                                                            src={check}
                                                                                            alt="Selected" />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="fixed bottom-0 w-full ">
                                                            {selectedImages.length !== 0 ?
                                                                (<div
                                                                    className=" items-center justify-end inline-flex bg-[#F9F9F9B2] bg-opacity-80 backdrop-blur-sm h-full w-full">
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedImages([])
                                                                            toggleGenModel()
                                                                        }}
                                                                        className="border-2 bg-white border-lite-red gap-x-4 text-black rounded-3xl normal-case font-normal text-sm px-4 py-2 my-2 mr-4">Cancel
                                                                    </button>

                                                                    <button
                                                                        onClick={() => handleSaveModel()}
                                                                        className="border-2 bg-selected border-lite-red gap-x-4 text-white rounded-3xl normal-case font-normal text-sm px-4 py-2 my-2 mr-8">Save
                                                                    </button>
                                                                </div>)
                                                                : (<div
                                                                    className="flex items-center justify-center  bg-[#F9F9F9B2] bg-opacity-80 backdrop-blur-sm h-full">
                                                                    <button
                                                                        onClick={() => {
                                                                            handleGenerateFashionModel();
                                                                        }}
                                                                        className="border-2 bg-white border-selected text-selected rounded-full normal-case font-semibold text-sm px-4 py-2 my-2">Generate
                                                                        More
                                                                    </button>
                                                                </div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    (<>
                                    </>)}
                            </div>
                        </div>
                        {isProcessing ? (
                            <div className="relative z-10" aria-labelledby="modal-title" role="dialog"
                                aria-modal="true">
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                    <div
                                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                        <div
                                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                        <h3 className="text-lg font-semibold leading-6 text-gray-900"
                                                            id="modal-title">Generating fashion models</h3>
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500 my-4">Please
                                                                wait...</p>

                                                            <div role="status">
                                                                <svg aria-hidden="true"
                                                                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                    viewBox="0 0 100 101" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor" />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="currentFill" />
                                                                </svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)}

                        {showImageViewer ? (
                          <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                          <div className="fixed inset-0 z-30 flex  items-center justify-center overflow-y-auto">
                            <div className="flex relative h-[40rem] bg-white w-max p-4 my-auto text-center rounded-xl flex-shrink">
                              <div className="relative flex shadow-3xl rounded-lg items-center justify-center border-2 m-6 h-auto border-lite-red w-[24rem]">
                                <img onClick={(e) => {
                                  setShowImageViewer(false);
                                  e.preventDefault();
                                }} src={selectedPreview === 'model' ? selectedModel.src : selectedProduct.src} alt="image" className="w-full h-full object-contain rounded my-auto" />
                              </div>
                              <div className="relative flex items-start justify-start h-full overflow-y-auto md:overflow-x-hidden w-fit flex-shrink">
                                <div className="grid grid-rows-1 sm:grid-cols-2 lg:grid-cols-3 max-2xl gap-2 m-4 w-auto items-start justify-start ">
                                  {previewGrid.map((img) => (
                                    <div
                                      key={img.id}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        selectedPreview === 'model' ? handleSelectModel(img) : handleSelectProduct(img);
                                      }}
                                      className={`p-2 relative w-36 h-[16rem] cursor-pointer bg-opacity-40 bg-[#FCEFEF66] border-2 border-lite-red aspect-square rounded-2xl ${
                                        selectedPreview === "model"
                                          ? selectedModel.id === img.id
                                            ? 'border-2 border-solid border-selected'
                                            : ''
                                          : selectedProduct.id === img.id
                                          ? 'border-2 border-solid border-selected'
                                          : ''
                                      }`}
                                      
                                    >
                                      <img
                                        src={img.src}
                                        className="w-full h-full aspect-auto object-top object-cover rounded-lg"
                                        alt={`Image ${img.id}`}
                                        placeholder={img.src}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <img
                                onClick={() => {
                                  setShowImageViewer(false);
                                }}
                                className="absolute top-2 right-2 w-4 h-4 cursor-pointer"
                                src={close}
                                alt="close"
                              />
                            </div>
                          </div>
                        </div>
                        

                        ) : (<div></div>)}
                    </div>
                    )
                    :
                    (
                        <div className="flex h-screen items-center justify-center">
                            <Spinner />
                        </div>)
                }
            </div>
            </div>
        </div>);
}


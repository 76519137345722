import React, {useEffect, useState, useRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearData, setAuthenticated, setUserData, setUserID} from "../store/AuthActions";
import {selectAccessToken, selectUserData, selectAuthenticated} from "../selectors";
import {LEVITON_SERVER_URL} from "../config";
import defaultprofile from "../assets/profile.svg"
import DropDownArrow from "../assets/DropDownArrow.svg"
import menu from "../assets/menu.svg"
import {useAuthentication} from "../Authentication";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";

export default function Navbar(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authenticated = useSelector(selectAuthenticated)
    // const authenticated = sessionStorage.getItem('authenticated')
    const [user_data, setuserData] = useState();
    const accessToken = useSelector(selectAccessToken);
    const storedUserData = localStorage.getItem('user_data');
    const userdata = JSON.parse(useSelector(selectUserData))
    // const userdata = JSON.parse(storedUserData);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileDropdown, setMobileDropdown] = useState(false);
    const isAuthenticated = useAuthentication()
    const dropdownRef = useRef(null);
    const mobileDropdownRef = useRef(null);

    useEffect(() => {
            const fetchUserData = async () => {

                if (authenticated) {
                    try {
                        console.log("access in fetch data", accessToken)
                        const response = await fetch(`${LEVITON_SERVER_URL}/api/v1/user/get/`, {
                            method: 'GET',
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            const userid = data.id
                            dispatch(setUserID(userid));
                            const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                            dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                            setuserData(dataWithoutPassword)
                            console.log("setting user_data from nav")
                        } else {
                            console.error('Failed to fetch user data');
                        }
                    } catch (error) {
                        console.error('Error fetching user data:', error);

                    }
                }

            };
            fetchUserData().then(r => {
            })

            // const handleBeforeUnload = (event) => {
            //     // Check if the user is authenticated and perform cleanup
            //     if (authenticated) {
            //         console.log("Setting authenticated token to false before unload");
            //         sessionStorage.clear();
            //         dispatch(setAuthenticated(false))
            //     }
            // };

            // window.addEventListener("beforeunload", handleBeforeUnload);

            // // Clean up event listener on component unmount
            // return () => {
            //     window.removeEventListener("beforeunload", handleBeforeUnload);
            // };

        },
        [accessToken, authenticated,])


    function logout() {
        console.log("logout is called")
        dispatch(clearData());
        localStorage.clear();
        sessionStorage.clear()
        dispatch(setAuthenticated(false))
        navigate("/login");
    }

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/login");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    const handleLogout = (e) => {
        e.preventDefault()
        logout()
    }


    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const handleOutsideClickDropDown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            toggleDropdown();
        }
    };

    useEffect(() => {

        document.addEventListener("mousedown", handleOutsideClickDropDown);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClickDropDown);
        };


    }, [dropdownOpen]);

    const closeMobileDropdown = () => {
        setMobileDropdown(false);
    };

    const toggleMobileDropdown = () => {
        setMobileDropdown(!mobileDropdown);
    };

    const handleOutsideClickMobileDropdown = (e) => {
        if (mobileDropdownRef.current && !mobileDropdownRef.current.contains(e.target)) {
            toggleMobileDropdown();
        }
    };

    useEffect(() => {

        document.addEventListener("mousedown", handleOutsideClickMobileDropdown);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClickMobileDropdown);
        }

    }, [mobileDropdown]);

    return (
        <nav
            className="fixed top-0 z-40 bg-[#FFFFFF4D] bg-opacity-30 backdrop-blur-2xl shadow-navbarShadow h-auto w-screen sm:w-full">
            <div className=" mx-auto my-auto py-auto max-w-7xl flex items-center justify-between p-2 sm:px-4 ">
                <div className="flex flex-row items-center mr-6 ">
                    <div className="w-full h-full items-center flex justify-center">
                        <img className="flex md:hidden w-8 h-8 object-contain cursor-pointer mr-4 " src= {menu}></img>
                        <img
                        className="flex w-auto h-14 object-cover object-top" src={TryonLabsLogo} alt="tryon labs logo"/><span className=" hidden md:block ml-4 text-2xl font-bold font-Lato">TryOn Labs</span></div>
                    {/* <span className="tracking-tight "><a href="/" className=" font-bold text-[32px] leading-6 font-baloo text-black">Tryon Labs</a></span> */}
                    {/* bg-gradient-to-r from-[#F05941] to-[#872341] bg-clip-text text-transparent  */}
                </div>
                <div className="flex items-center justify-center md:w-max pointer-events-auto">
                    <a target="_blank" href="https://playground.tryonlabs.ai"><button className=" flex items-center justify-center md:w-max md:py-4  sm:py-3 py-2 px-[2rem] font-semibold sm:text-base text-xs leading-6 not-italic text-white border-1 bg-gradient-to-r from-[#F05941] to-[#BE3144] backdrop-blur-[50px] border-lite-red rounded-full">Try Our PlayGround</button></a>
                </div>
                {authenticated ? (
                    <div>
                        <div className="flex items-center md:space-x-3 relative min-md sm:visible invisible">
                            <ul className="font-medium font-OpenSans flex md:p-0  md:flex-row md:space-x-4 rtl:space-x-reverse mt-0">
                                <li>
                                    <a href="/tryon"
                                       className="block py-2 px-1"
                                       aria-current="page">Virtual Try-On</a>
                                </li>
                                <li>
                                    <a href="/Cataloging.jsx"
                                       className="block py-2 px-1"
                                       aria-current="page">Catalogue</a>
                                </li>
                            </ul>
                            <img className="w-12 h-12 rounded-full object-cover object-top"
                                 src={userdata?.profile ? userdata.profile : defaultprofile} alt="user photo"/>
                            <span
                                className=" font-OpenSans text-sm capitalize font-semibold leading-6">{user_data ? user_data.username : "UserData is not fetched"}</span>
                            <button
                                type="button"
                                onClick={toggleDropdown}
                                className="flex text-sm p-1 rounded-full "
                                id="user-menu-button"
                                aria-expanded={dropdownOpen}
                                onFocus={closeDropdown}>
                                <span className="sr-only">Open user menu</span>
                                <img className="items-center justify-center"
                                     src={DropDownArrow}
                                     alt="DropDown"/>
                            </button>
                            {dropdownOpen && (
                                <div
                                    className="absolute z-40 top-8 right-0 mt-2 text-base list-none bg-[#FFF] rounded-lg shadow">
                                    <div ref={dropdownRef}>

                                        <div className="font-OpenSans px-4 py-3">
                                            <span
                                                className="block text-md font-normal text-gray-900">{user_data && user_data.username ? user_data.username : ' username'}</span>
                                            <span
                                                className="block text-sm text-gray-500 truncate">{user_data && user_data.email ? user_data.email : 'user@mail.com'}</span>
                                        </div>
                                        <ul className="font-OpenSans py-2" aria-labelledby="user-menu-button">
                                            <li>
                                                <a href="/profile"
                                                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    Profile
                                                </a>
                                            </li>
                                            <li>
                                                <div onClick={handleLogout}>
                                                    <a href="#"
                                                       className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100">
                                                        Log out
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="sm:visible hidden invisible">
                        <div className="flex font-OpenSans m-3 items-end space-x-4">
                            <a href="/signup" className="text-md text-gray-700 hover:bg-gray-100 ">
                                Sign Up
                            </a>
                            <a href="/login" className="text-md text-gray-700 hover:bg-gray-100">
                                Log in
                            </a>
                        </div>
                    </div>
                )}

                {/* <div className="flex w-full items-end justify-end sm:hidden visible mr-4 flex-shrink ">
                    <button
                        type="button"
                        onClick={toggleMobileDropdown}
                        aria-expanded={mobileDropdown}
                        onFocus={closeMobileDropdown}

                        className="flex items-center justify-end md:w-0 w-max sm:hidden visible sm:invisible pointer-events-auto">
                        <img src={menu} className="w-8 h-8"/>
                    </button>
                </div> */}
                {/* <div className="sm:hidden sm:invisible">
                    {mobileDropdown && (
                        <div
                            className="absolute z-40 top-16 right-8 mt-2 text-base list-none bg-[#FFF] rounded-lg shadow">
                            <div ref={mobileDropdownRef}>

                                <div className="font-OpenSans px-4 py-3">
                                    <span
                                        className="block text-md font-normal text-gray-900">{user_data && user_data.username ? user_data.username : ' username'}</span>
                                    <span
                                        className="block text-sm text-gray-500 truncate">{user_data && user_data.email ? user_data.email : 'user@mail.com'}</span>
                                </div>
                                {authenticated ? (
                                    <ul className="font-OpenSans py-2" aria-labelledby="user-menu-button">
                                        <li>
                                            <a href="/tryon"
                                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                               aria-current="page">Virtual Try-On</a>
                                        </li>
                                        <li>
                                            <a href="/Cataloging.jsx"
                                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                               aria-current="page">Catalogue</a>
                                        </li>
                                        <li>
                                            <a href="/profile"
                                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                Profile
                                            </a>
                                        </li>
                                        <li>
                                            <div onClick={handleLogout}>
                                                <a href="#"
                                                   className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100">
                                                    Log out
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="font-OpenSans py-2" aria-labelledby="user-menu-button">
                                        <li>
                                            <a href="/signup"
                                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">
                                                Sign Up
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/login"
                                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">
                                                Log in
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    )}
                </div> */}
            </div>
        </nav>
    );
}

import { LEVITON_SERVER_URL } from "./config";
import axios from "axios";
import { setAccessToken, setAuthenticated, setRefreshToken } from "./store/AuthActions";
import { useDispatch } from "react-redux";

export const useAuthentication = () => {
  const dispatch = useDispatch();

  const isAuthenticated = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      console.log('token', accessToken);

      if (!accessToken) {
        dispatch(setAuthenticated(false));
        return false;
      }

      const response = await axios.get(`${LEVITON_SERVER_URL}/api/v1/user/get/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log('Token is valid', response);
      dispatch(setAuthenticated(true));
      return true;
    } catch (error) {
      console.log('Token is invalid', error);

      if (error.response && error.response.status === 401) {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const newAccessToken = await refreshAccessToken(refreshToken);

          if (!newAccessToken) {
            dispatch(setAuthenticated(false));
            return false;
          }

          // Check if the refreshed token is valid by making a request to the server
          const verificationResponse = await axios.get(`${LEVITON_SERVER_URL}/api/v1/user/get/`, {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          });

          // If the request is successful, the refreshed token is valid
          console.log('Token is valid after refresh', verificationResponse);
          dispatch(setAuthenticated(true));
          return true;
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          dispatch(setAuthenticated(false));
          return false;
        }
      }
      dispatch(setAuthenticated(false));
      return false;
    }
  };

  // Function to refresh the access token
  const refreshAccessToken = async (currentRefreshToken) => {
    try {
      const response = await axios.post(`${LEVITON_SERVER_URL}/api/token/refresh/`, {
        refresh: currentRefreshToken,
      });
      const newAccessToken = response.data.access;
      // const newRefreshToken = response.data.refresh;
      dispatch(setAccessToken(newAccessToken));
      // dispatch(setRefreshToken(newRefreshToken));
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  };

  return { isAuthenticated };
};

import React, { useEffect, useState, useCallback } from "react";
import landingGradient2 from "../assets/landingGradient2.jpg";
import garment from "../assets/landingDemoGarment.png";
import model from "../assets/landingDemoModel.png";
import result from "../assets/landingDemoResult.png";
import arrowsvg from "../assets/Arrow.svg";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../Authentication";
import { selectAuthenticated } from "../selectors";

export default function LandingPage2() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuthentication();
    const authenticated = useSelector(selectAuthenticated);
    const [ethnicity, setEthnicity] = useState("white")
    const [gender, setGender] = useState("female")
    const [age, setAge] = useState(30)
    const [size, setSize] = useState('s')
    const [height, setHeight] = useState(152)
    console.log("authenticated in the landing page", authenticated)

    const Button = ({ className, name, selected, onClick }) => {
        return <button className={` ${selected ? ' border-selected text-selected' : 'border-lite-red'} mr-2 my-1 capitalize rounded-full border-2 px-2 py-1 font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic bg-inherit ${className}`} onClick={onClick}> {name} </button>;
    }


    const OptionGroup = ({ title, options, selected, onSelect,className }) => (
        <div className={`flex flex-col flex-wrap w-full h-full bg-[#FFFFFFCC] border-1 mb-2 flex-shrink p-2 border-lite-red rounded-lg shadow-min-shadow ${className}`}>
            <span className="items-start justify-start font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic">{title}</span>
            <div className="flex flex-row flex-wrap w-full items-start">
                {options.map((option) => (
                    <Button
                        key={option}
                        selected={selected === option}
                        name={option}
                        onClick={() => onSelect(option)}
                    />
                ))}
            </div>
        </div>
    );

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/login");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    const handleNavigateTryon= () =>{
        navigate("/tryon")
    }

    return (
        <div className=" w-full h-screen overflow-y-hidden bg-gradient-to-r from-[#F8CAC3] to-[#F1D7FB]">
            <div className="fixed bottom-0 right-0 left-0 h-max w-auto opacity-50 "> <img className="w-screen h-[22rem] object-stretch" src={landingGradient2} /></div>

            <Navbar page="landing_page_2" />
            <div className='h-[calc(100vh-4rem)] max-w-7xl mx-auto w-full overflow-y-auto pt-[2rem] '>
                {/* headings and button div  */}
                <div className="flex sm:flex-row font-OpenSans not-italic items-start justify-between w-full">
                    <div className="flex flex-col justify-center w-3/4 h-full">
                        <span className=" w-8/12 font-bold text-5xl leading-[54px] bg-gradient-to-r from-[#F05941] to-[#F98700] bg-clip-text text-transparent mb-[2rem] ">Generate fashion product photos with AI</span>
                        <span className=" w-11/12 flex font-normal text-base leading-6 mb-12">Levit8 helps fashion retailers reduce photo shoots costs and boost sales using AI-generated models.</span>
                    </div>

                    <div aria-label="span-container" className="flex flex-col font-OpenSans not-italic w-1/4 items-end h-full">

                        <div className="flex items-center justify-center w-max">
                            <button onClick={handleNavigateTryon} className="w-max py-6 px-8 font-semibold text-base leading-6 not-italic text-white border-1 bg-gradient-to-r from-[#F05941] to-[#BE3144] backdrop-blur-[50px] border-lite-red rounded-full">Start Try a Product</button>
                        </div>
                    </div>
                </div>

                {/* Rest of the height  */}

                <div className="flex sm:flex-row flex-col w-full h-fit items-center justify-between pb-[2rem] ">
                    {/* filters  */}
                    <div className="flex flex-col sm:w-3/12 h-min mr-[5rem]">

                        <div className="flex flex-col w-11/12 h-fit bg-inherit border backdrop-blur-[50px] border-selected rounded-2xl p-3 ">
                            <OptionGroup title="Select Gender" options={["female", "male"]} selected={gender} onSelect={setGender} />

                            <OptionGroup title="Model Ethnicity" options={["white", "asian", "black", "hispanic"]} selected={ethnicity} onSelect={setEthnicity} />

                            <OptionGroup title="Age Group" options={[30, 40, 50]} selected={age} onSelect={setAge} />

                            <OptionGroup title="Size" options={["S", "M", "L", "XL", "XXL"]} selected={size} onSelect={setSize} />

                            <OptionGroup className="-mb-0" title="Height" options={[152, 160, 165, 168, 172, 180]} selected={height} onSelect={setHeight} />
                        </div>


                        <div className="flex flex-col w-full h-16">
                            <div className="flex w-full h-auto items-center justify-center">
                                <img src={arrowsvg} className="w-max h-max object-cover object-top" />
                            </div>
                            <span className=" mb-2 w-10/12 h-max flex font-OpenSans not-italic font-bold text-base leading-5 z-10">Select model body measurement</span>
                        </div>

                    </div>

                    {/* Model & Garment  */}
                    <div className="flex flex-row h-fit sm:w-5/12 ">
                        <div className="flex flex-col h-full w-1/2">
                            <div className="flex flex-col w-full h-full bg-inherit border backdrop-blur-[50px] border-selected rounded-2xl p-3 ">
                                <div className="flex items-center justify-center rounded-lg h-full w-full bg-white mb-3">
                                    <img className="w-suto h-[16rem] object-cover object-top" src={garment} />
                                </div>
                                <div className="flex items-center justify-center rounded-lg h-full w-full bg-white ">
                                    <img className="w-auto h-[16rem] object-cover object-top" src={garment} />

                                </div>
                            </div>

                            <div className="flex flex-col w-full h-16">
                                <div className="flex w-full h-auto items-center justify-center">
                                    <img src={arrowsvg} className="w-max h-max" />
                                </div>
                                <span className="w-10/12 h-max flex font-OpenSans not-italic font-bold text-base leading-5 z-10">Select model wearable type (Top, Bottom, Fullbody)</span>
                            </div>
                        </div>

                        <div className="flex h-[32rem] w-1/2 items-center justify-center -ml-2 mr-10">
                            <div className="flex h-full w-full items-center ">

                                <div className="flex w-max h-max bg-inherit border backdrop-blur-[50px] border-selected rounded-2xl p-3 ">
                                    <div className="flex items-center justify-center rounded-lg h-max w-fit bg-white">
                                        <img className=" w-60 h-80 object-cover object-top" src={model}></img>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Model  */}

                    {/* result   */}
                    <div className="flex flex-col h-full sm:w-3/12">

                        <div className="flex w-full h-full bg-inherit border backdrop-blur-[50px] border-selected rounded-2xl p-3 ">
                            <div className="flex items-center justify-center rounded-lg h-max w-full bg-white">
                                <img className=" w-60 h-[32rem] object-cover object-top" src={result}></img>
                            </div>
                        </div>

                        <div className="flex flex-col w-full h-16">
                            <div className="flex w-full h-auto items-center justify-center">
                                <img src={arrowsvg} className="w-max h-max" />
                            </div>
                            <span className="w-10/12 h-max mb-2 flex font-OpenSans not-italic font-bold text-base leading-5 z-10">AI generated model</span>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}